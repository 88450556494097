<ng-container *ngIf="modules$ | async as modules">
    <div class="section">
        <ngx-icon-box class="section-header" [title]="modules.title" [iconDetails]="modules.icon"></ngx-icon-box>
        <div class="section-items-container">
            <ng-container *ngFor="let module of modules.children">
                <nb-card class="section-item" [ngClass]="{'disabled-link': !module.enabled}" [routerLink]="module.link">
                    <nb-card-header class="dashboard-card-header">
                        <ngx-icon-box
                            class="section-item-header"
                            [title]="module.title"
                            [subtitle]="module.subTitle"
                            [iconDetails]="module.icon"
                        >
                        </ngx-icon-box>
                        <nb-icon class="arrow-forward" icon="arrow-forward"></nb-icon>
                    </nb-card-header>
                    <nb-card-body class="dashboard-card-body">
                        <ng-container *ngFor="let summaryValue of module.summaryValues">
                            <div class="display-flex flex-column">
                                <label class="text-bold">
                                    {{ summaryValue.value }}
                                </label>
                                <label class="text-hint">
                                    {{ summaryValue.title }}
                                </label>
                            </div>
                        </ng-container>
                        <nb-progress-bar
                            class="width-100"
                            status="primary"
                            size="tiny"
                            [value]="module.progress"
                        ></nb-progress-bar>
                    </nb-card-body>
                </nb-card>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="tools$ | async as tools">
    <div class="section">
        <ngx-icon-box class="section-header" [title]="tools.title" [iconDetails]="tools.icon"></ngx-icon-box>
        <div class="section-items-container">
            <ng-container *ngFor="let tool of tools.children">
                <nb-card class="section-item" [ngClass]="{'disabled-link': !tool.enabled}" [routerLink]="tool.link">
                    <nb-card-header class="dashboard-card-header">
                        <ngx-icon-box class="section-item-header" [title]="tool.title" [subtitle]="tool.subTitle">
                        </ngx-icon-box>
                        <nb-icon class="arrow-forward" icon="arrow-forward"></nb-icon>
                    </nb-card-header>
                    <nb-card-body class="dashboard-card-body">
                        <ng-container *ngFor="let summaryValue of tool.summaryValues">
                            <div class="display-flex flex-column">
                                <label class="text-bold">
                                    {{ summaryValue.value }}
                                </label>
                                <label class="text-hint">
                                    {{ summaryValue.title }}
                                </label>
                            </div>
                        </ng-container>
                        <nb-progress-bar
                            class="width-100"
                            status="primary"
                            size="tiny"
                            [value]="tool.progress"
                        ></nb-progress-bar>
                    </nb-card-body>
                </nb-card>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="archives$ | async as archives">
    <div class="section">
        <ngx-icon-box class="section-header" [title]="archives.title" [iconDetails]="archives.icon"></ngx-icon-box>
        <div class="section-items-container">
            <ng-container *ngFor="let archive of archives.children">
                <nb-card
                    class="section-item"
                    [ngClass]="{'disabled-link': !archive.enabled}"
                    [routerLink]="archive.link"
                >
                    <nb-card-header class="dashboard-card-header">
                        <ngx-icon-box class="section-item-header" [title]="archive.title" [subtitle]="archive.subTitle">
                        </ngx-icon-box>
                        <nb-icon class="arrow-forward" icon="arrow-forward"></nb-icon>
                    </nb-card-header>
                    <nb-card-body class="dashboard-card-body multiline-summary">
                        <ng-container *ngFor="let summaryValue of archive.summaryValues">
                            <div class="display-flex flex-row">
                                <label class="text-hint" [style.width.%]="40">{{ summaryValue.title }} : </label>
                                <label [style.width.%]="60">{{ summaryValue.value }}</label>
                            </div>
                        </ng-container>
                    </nb-card-body>
                </nb-card>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="configuration$ | async as configuration">
    <div class="section">
        <ngx-icon-box class="section-header" [title]="configuration.title" [iconDetails]="configuration.icon">
        </ngx-icon-box>
        <div class="section-items-container">
            <ng-container *ngFor="let config of configuration.children">
                <nb-card class="section-item" [ngClass]="{'disabled-link': !config.enabled}" [routerLink]="config.link">
                    <nb-card-header class="dashboard-card-header">
                        <ngx-icon-box class="section-item-header" [title]="config.title" [subtitle]="config.subTitle">
                        </ngx-icon-box>
                        <nb-icon class="arrow-forward" icon="arrow-forward"></nb-icon>
                    </nb-card-header>
                    <nb-card-body class="dashboard-card-body">
                        <ng-container *ngFor="let summaryValue of config.summaryValues">
                            <ngx-icon-box
                                class="config-card-body"
                                [iconDetails]="config.icon"
                                [title]="summaryValue.value"
                                [subtitle]="summaryValue.title"
                            ></ngx-icon-box>
                        </ng-container>
                    </nb-card-body>
                </nb-card>
            </ng-container>
        </div>
    </div>
</ng-container>
