import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, InfoBlock, InfrastructureForecastBySeasons, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export interface FeederInfo extends InfoBlock {}

export abstract class FeederLoadForecastService {
    /*
     * Infrastructure: results for feeder(s)
     */
    abstract getFeederPivot(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getFeederInfo(workflowItemId: string, scenarioId: string, feederId: string): Observable<FeederInfo[]>;

    abstract getFeederForecastBySeasons(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons>;

    abstract getFeederForecastByFactors(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getFeederPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getFeederInfrastructureList(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
    ): Observable<DataSource>;

    abstract getFeederInfrastructureListCSV(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
