import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    fullName: string;
    date: Date;
    role: string;
    picture: string;
    timezone: string;
    tenantId: string;
    studyStrategy: {
        latestAsDefault: boolean;
        defaultWorkflowId: string;
        defaultWorkflowItemId: string;
    };
}

export abstract class UsersService {
    abstract getCurrentUser(): Observable<User>;

    abstract get(id: string): Observable<User>;

    abstract update(user: User): Observable<any>;

    abstract create(dto: any): Observable<User>;

    abstract delete(id: string): Observable<boolean>;

    abstract getUsersDataSource(): Observable<DataSource>;
}
