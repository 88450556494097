<nb-card>
    <nb-card-header>
        {{ confirmationData.title }}

        <button ghost status="basic" nbButton (click)="cancel()">
            <nb-icon icon="close-outline"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        {{ confirmationData.message }}
    </nb-card-body>

    <nb-card-footer>
        <button nbButton fullWidth status="basic" (click)="cancel()">
            {{ confirmationData.cancelText ? confirmationData.cancelText : "Cancel" }}
        </button>

        <button nbButton fullWidth status="info" class="ml-3" (click)="confirm()">
            {{ confirmationData.confirmText ? confirmationData.confirmText : "Confirm" }}
        </button>
    </nb-card-footer>
</nb-card>
