import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SidebarStore} from '../../../@store';

@Component({
    selector: 'ngx-pages-group-popover',
    templateUrl: './pages-group-popover.component.html',
    styleUrls: ['./pages-group-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagesGroupPopoverComponent {
    @Input() pages: any[];

    constructor(public sidebarStore: SidebarStore) {}

    topMenuClicked() {
        // Collapse sidebar when navigating
        this.sidebarStore.toggleSidebarState(false);
    }
}
