import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {JobsService, OutputReportSource, Study, StudyCollection} from '../../../interfaces/engin/jobs';
import {JobsApi} from '../api/jobs.api';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '../../../interfaces/system/system-common';

@Injectable()
export class JobsServiceImpl extends JobsService {
    constructor(private api: JobsApi) {
        super();
    }

    getStudyCollectionsDataSource(): Observable<DataSource> {
        return this.api.getStudyCollectionsDataSource();
    }

    getStudyCollections(): Observable<StudyCollection[]> {
        return this.api.getStudyCollections();
    }

    getJobAssetInfo(jobId: string, assetId: string): Observable<any> {
        return this.api.getJobAssetInfo(jobId, assetId);
    }

    getOutputReportGeneric(reportRequest): Observable<OutputReportSource> {
        return this.api.getOutputReportGeneric(reportRequest);
    }

    updateDefaultStudyStrategy(latestAsDefault: boolean, defaultWorkflowId: string): Observable<any> {
        return this.api.updateDefaultStudyStrategy(latestAsDefault, defaultWorkflowId);
    }

    getLatestCompletedStudyCollection(): Observable<StudyCollection> {
        return this.api.getLatestCompletedStudyCollection();
    }

    /* deprecated */
    legacyExecuteNewStudyCollection(automated: boolean, dataType?: string, date?: string): Observable<any> {
        return this.api.legacyExecuteNewStudyCollection(automated, dataType, date);
    }

    executeNewStudyFromSnapshot(snapshotId: number, automated: boolean, date?: string): Observable<any> {
        return this.api.executeNewStudyFromSnapshot(snapshotId, automated, date);
    }

    /* deprecated */
    legacyExecuteHealthIndexStudy(automated: boolean, date?: string): Observable<any> {
        return this.api.legacyExecuteHealthIndexStudy(automated, date);
    }

    getStudyCollectionById(collectionId: string): Observable<StudyCollection> {
        return this.api.getStudyCollectionById(collectionId);
    }

    getStudyById(studyId: string): Observable<Study> {
        return this.api.getStudyById(studyId);
    }

    updateStudyCollectionLabel(studyId: string, label: string): Observable<any> {
        return this.api.updateStudyCollectionLabel(studyId, label);
    }

    findBySnapshotId(id: string): Observable<APIResponse<any>> {
        return this.api.findBySnapshotId(id);
    }
}
