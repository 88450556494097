import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ReportingService} from '@core/interfaces/engin/reporting';
import {ReportingApi} from '@core/backend/engin/api/reporting.api';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

@Injectable()
export class ReportingServiceImpl extends ReportingService {
    constructor(private api: ReportingApi) {
        super();
    }

    /* public initSettingsByWorkflowItem(workflowItemId: string): Observable<LoadForecastSettings> {
        return this.api.initSettingsByWorkflowItem(workflowItemId);
    }*/

    public getDataExtractPreview(
        workflowItemId: string,
        outputCategory: string,
        outputType: string,
    ): Observable<DataSource> {
        return this.api.getDataExtractPreview(workflowItemId, outputCategory, outputType);
    }

    public getDataExtractCsv(
        workflowItemId: string,
        outputCategory: string,
        outputType: string,
        outputResult: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getDataExtractCsv(workflowItemId, outputCategory, outputType, outputResult);
    }
}
