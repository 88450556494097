<div class="page-container">
    <div class="page-content-container">
        <div class="kpi-charts-container">
            <nb-card class="kpi-chart-card" *ngFor="let number of [0, 1, 2, 3]">
                <nb-card-body>
                    <div class="chart-card-header">
                        <label class="primary-header"><span>empty title</span></label>
                        <label class="sub-header"><span>subtitle</span></label>
                    </div>
                    <div class="kpi-body">
                        <div class="kpi-bar">
                            <div class="kpi-bar-part">
                                <div class="kpi-bar-top"></div>
                                <div class="kpi-bar-arrow"></div>
                            </div>
                            <div class="kpi-bar-part">
                                <div class="kpi-bar-bottom kpi-empty-color"></div>
                                <div class="kpi-bottom-empty-space"></div>
                            </div>
                        </div>
                        <div class="kpi-values">
                            <nb-card class="kpi-info-card">
                                <nb-card-header>
                                    <span>Delta:%</span>
                                </nb-card-header>
                                <nb-card-body><span>empty</span></nb-card-body>
                            </nb-card>
                            <nb-card class="kpi-info-card">
                                <nb-card-body><span>Residual: </span> </nb-card-body>
                            </nb-card>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>
