import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

/*
 * Nebular imports
 */
import {
    NbActionsModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbRadioModule,
    NbSelectModule,
    NbSidebarModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbThemeModule,
    NbToastrModule,
    NbToggleModule,
    NbTooltipModule,
    NbWindowModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

/*
 * Components
 */
import * as components from './components';
import {EmptyDataComponent} from './components';

/*
 * Pipes
 */
import {
    CapitalizePipe,
    DateFormatPipe,
    DatetimeFormatPipe,
    NumberWithCommasPipe,
    PluralPipe,
    RoundPipe,
    SafePipe,
    ShowEnabledPipe,
    TimeLocalizePipe,
    TimeUnlocalizePipe,
    TimezonePipe,
    TimingPipe,
} from './pipes';

/*
 * Directives
 */
import {DynamicComponentDirective, ThrottleClickDirective} from './directives';

/*
 * Empty components
 */
import {
    EmptyBarChartStackedComponent,
    EmptyBarChartStackedSingleComponent,
    EmptyColumnChartComponent,
    EmptyKpiChartComponent,
    EmptyLabelValueVerticalComponent,
    EmptyLineChartComponent,
    EmptyPieChartComponent,
    EmptyPieChartDonutComponent,
    EmptyTableComponent,
    EmptyTableSmallComponent,
} from './empty-components';

/*
 * Styles and theme
 */
import {DEFAULT_THEME} from './styles/theme.default';
import {DARK_THEME} from './styles/theme.dark';

// Other
import {AuthModule} from '@auth/auth.module';
import {RouterModule} from '@angular/router';
import {SidebarStore} from '../@store';

// External
import {NgxEchartsModule} from 'ngx-echarts';

import {RegisterUserComponent} from './components/register-form/register-user.component';
import {NgxValidationMessageComponent} from './components/validation-message/validation-message.component';
import {LoginComponent} from './components/login-form/login.component';
import {ReportTypeIndicatorComponent} from './components/report-type-indicator/report-type-indicator.component';
import {StudyCollectionSelectorComponent} from './components/study-collection-selector/study-collection-selector.component';
import {ChartLegendBarComponent} from './components/chart-legend-bar/chart-legend-bar.component';
import {ChartTitleBarComponent} from './components/chart-title-bar/chart-title-bar.component';
import {InlineRadioGroupComponent} from './components/inline-radio-group/inline-radio-group.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {SystemInfoDialogComponent} from './components/system-info-dialog/system-info-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StudySelectorViewModel} from './components/study-selector/study-selector.vm';
import {HelpSupportComponent} from './components/help-support/help-support.component';
import {HeaderSettingsComponent, HeaderSettingsDialogComponent} from '@theme/components';
import {NgxSmartTableModule} from '@mominsamir/ngx-smart-table';
import {NumericRangeControlComponent} from './components/numeric-range-control/numeric-range-control.component';
import {TableSettingsComponent} from './components/table-settings/table-settings.component';
import {TableSettingsPopupComponent} from '@theme/components/table-settings/table-settings-popup.component';
import {NumericRangeFilterComponent} from '@theme/components/data-table/custom-filters/numeric-range-filter.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
const NB_MODULES = [
    NbEvaIconsModule,
    NbCardModule,
    NbLayoutModule,
    NbTabsetModule,
    NbMenuModule,
    NbActionsModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbSecurityModule, // *nbIsGranted directive,
    NbProgressBarModule,
    NbButtonModule,
    NbListModule,
    NbToastrModule,
    NbToggleModule,
    NbInputModule,
    NbFormFieldModule,
    NbDatepickerModule,
    NbDialogModule,
    NbWindowModule,
    NbAlertModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbIconModule,
    NbFormFieldModule,
    NbSpinnerModule,
];
const APP_MODULES = [NgxSmartTableModule, NgxEchartsModule];

const COMPONENTS = [
    NgxValidationMessageComponent,
    RegisterUserComponent,
    LoginComponent,
    components.ThemeSwitcherComponent,
    components.FooterComponent,
    components.ValueBoxComponent,
    components.CheckboxGroupComponent,
    components.IconBoxComponent,
    components.PagesGroupPopoverComponent,
    components.MenuComponent,
    components.SubmenuComponent,
    components.SubmenuPopoverComponent,
    components.RouteTabsetComponent,
    components.TablePagerComponent,
    components.SidebarActionComponent,
    components.SidebarActionPopoverComponent,
    components.ChartComponent,
    components.ChartSettingsComponent,
    components.TableSettingsComponent,
    components.TableSettingsPopupComponent,
    components.ChartSettingsPopupComponent,
    components.DefaultStudySelectorComponent,
    components.SliderComponent,
    components.FileUploadComponent,
    components.FileDownloadComponent,
    components.ActiveStudySelectorComponent,
    components.DataTableComponent,
    components.DateFilterComponent,
    components.NumericRangeFilterComponent,
    components.StringDateFilterComponent,
    components.ConformDialogComponent,
    components.InputErrorComponent,
    components.EmptyDataComponent,
    components.StatusCellComponent,
    components.MultiSelectFilterComponent,

    HelpSupportComponent,
    HeaderSettingsComponent,
    HeaderSettingsDialogComponent,

    // General
    PageHeaderComponent,
    // General, related to data audit tool
    InlineRadioGroupComponent,
    ChartTitleBarComponent,
    ChartLegendBarComponent,

    // General, related to reporting tool
    ReportTypeIndicatorComponent,
    StudyCollectionSelectorComponent,

    // Empty chart components
    EmptyBarChartStackedComponent,
    EmptyBarChartStackedSingleComponent,
    EmptyColumnChartComponent,
    EmptyKpiChartComponent,
    EmptyLabelValueVerticalComponent,
    EmptyLineChartComponent,
    EmptyPieChartComponent,
    EmptyPieChartDonutComponent,
    EmptyTableComponent,
    EmptyTableSmallComponent,
];

const ENTRY_COMPONENTS = [EmptyDataComponent];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    SafePipe,
    ShowEnabledPipe,
    TimezonePipe,
];
const DATA_PIPES = [TimeLocalizePipe, TimeUnlocalizePipe];

const DIRECTIVES = [DynamicComponentDirective, ThrottleClickDirective];
const VM = [StudySelectorViewModel];
const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'default',
        },
        [DEFAULT_THEME, DARK_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
    ...NbDialogModule.forRoot().providers,
    ...NbWindowModule.forRoot().providers,
    ...NbToastrModule.forRoot().providers,
];

@NgModule({
    imports: [
        AuthModule,
        RouterModule,
        ...BASE_MODULES,
        ...NB_MODULES,
        ...APP_MODULES,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    exports: [
        ...BASE_MODULES,
        ...NB_MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DATA_PIPES,
        ...DIRECTIVES,
        NumericRangeControlComponent,
    ],
    providers: [SidebarStore, DecimalPipe, DeviceDetectorService, ...VM],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DATA_PIPES,
        ...DIRECTIVES,
        SystemInfoDialogComponent,
        NumericRangeControlComponent,
        TableSettingsComponent,
    ],
    entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [...NB_THEME_PROVIDERS],
        };
    }
}
