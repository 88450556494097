import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    ConfigurationService,
    DatasetItem,
    TenantUnitsConfig,
    WorkflowConfig,
} from '@core/interfaces/common/configuration';
import {ConfigurationApi} from '../api/configuration.api';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class ConfigurationServiceImpl extends ConfigurationService {
    constructor(private api: ConfigurationApi) {
        super();
    }

    getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>> {
        return this.api.getWorkFlows();
    }

    getDatasets(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.getDatasets();
    }

    getTablesFromSchema(schemaName: string): Observable<APIResponse<string[]>> {
        return this.api.getTablesFromSchema(schemaName);
    }

    getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>> {
        return this.api.getTenantUnits();
    }
}
