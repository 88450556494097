import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ngx-slider',
    template: `
        <div class="slider-container">
            <input
                type="range"
                class="progress"
                [(ngModel)]="sliderValue"
                #val
                (input)="setValue(val.value)"
                [attr.min]="minValue"
                [attr.max]="maxValue"
            />
            <div class="progress-foreground" [style.width.%]="progressWidth"></div>
        </div>
    `,
    styleUrls: ['./slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements ControlValueAccessor {
    @Input('min') minValue: number;
    @Input('max') maxValue: number;

    private onChange: any = () => {};

    constructor(private cd: ChangeDetectorRef) {}

    get progressWidth(): number {
        return Math.round((this.sliderValue - this.minValue) / ((this.maxValue - this.minValue) / 100));
    }

    private _sliderValue: number;

    get sliderValue(): number {
        return this._sliderValue;
    }

    @Input('value')
    set sliderValue(value: number) {
        this._sliderValue = value;
        this.onChange(value);
        this.cd.markForCheck();
    }

    setValue(value: number) {
        this.sliderValue = +value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(value: number): void {
        this.sliderValue = value;
    }
}
