import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {StudiesStore} from '../../../@store';
import {NbToastrService} from '@nebular/theme';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Workflow} from '@core/interfaces/engin/workflow';

@Component({
    selector: 'ngx-active-study-selector',
    template: `
        <ng-container
            *ngIf="{
                activeWorkflowId: activeWorkflowId$ | async,
                dataLoading: this.studiesStore.dataLoading$ | async
            } as data"
        >
            <nb-icon
                icon="power-outline"
                class="not-active-study-icon"
                [ngClass]="{
                    'active-study-icon': data.activeWorkflowId === currentWorkflowId,
                    'not-active-study-icon': data.activeWorkflowId !== currentWorkflowId
                }"
                (click)="setActiveCollection(rowData, data.dataLoading)"
            >
            </nb-icon>
        </ng-container>
    `,
    styleUrls: ['./study-selector.component.scss'],
})
export class ActiveStudySelectorComponent extends Unsubscribable implements ViewCell, OnInit, OnDestroy {
    @Input() rowData: Workflow;
    @Input() value: string | number;

    private activeWorkflowId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    readonly activeWorkflowId$: Observable<number> = this.activeWorkflowId.asObservable();
    currentWorkflowId: number;

    constructor(public studiesStore: StudiesStore, private toastrService: NbToastrService) {
        super();
    }

    private activeStudySubscription: Subscription;

    ngOnInit(): void {
        this.activeStudySubscription = this.studiesStore.activeWorkflowId$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((activeWorkflowId) => {
                this.activeWorkflowId.next(parseInt(activeWorkflowId, 10));
            });
        this.currentWorkflowId = this.rowData.id;
    }

    setActiveCollection(rowData, dataLoading) {
        if (dataLoading) {
            this.toastrService.info(
                'Active workflow is already changing. Please wait for this to finish.',
                'Please wait',
                {
                    duration: 10000,
                },
            );
        } else {
            if (rowData.status !== 'SUCCESS') {
                this.toastrService.danger(
                    'Workflow is incomplete and cannot be made active at this time. Please ' +
                        'select a workflow which has been successfully completed.',
                    'Active workflow not updated!',
                    {duration: 10000},
                );
            } else {
                this.studiesStore.updateActiveWorkflowId(rowData.id);
            }
        }
    }

    ngOnDestroy() {
        this.activeStudySubscription && this.activeStudySubscription.unsubscribe();
    }
}
