<ng-container *ngIf="isAuthenticated$ | async" class="total-page-container">
    <nb-layout *ngIf="sidebarStore.sidebarStatus$ | async as sidebarStatus" windowMode>
        <!-- Page header -->
        <nb-layout-header *ngIf="sidebarStatus.isMainPage" data-html2canvas-ignore fixed>
            <ngx-header class="header" [currentUser]="(authService.onTokenChange() | async).getPayload()"></ngx-header>
        </nb-layout-header>

        <!-- Left navigation / sidebar menu -->
        <nb-sidebar
            *ngIf="sidebarStatus.isMainPage"
            data-html2canvas-ignore
            class="menu-sidebar"
            tag="menu-sidebar"
            [state]="sidebarStatus.expanded ? 'expanded' : 'compacted'"
            fixed
        >
            <ng-container *ngIf="pagesStore.activeFeature$ | async as activeFeature">
                <!-- Screen capture actions -->
                <ngx-sidebar-action-button
                    *ngIf="showReportConfigShortcut()"
                    class="menu-action"
                    text="Get Capture"
                    icon="camera"
                    [expanded]="sidebarStatus.expanded"
                    (doAction)="getCapture()"
                >
                </ngx-sidebar-action-button>

                <!-- Page / tab navigation menu -->
                <ngx-menu [items]="activeFeature.children" [expanded]="sidebarStatus.expanded"></ngx-menu>
            </ng-container>

            <nb-sidebar-footer class="sidebar-footer">
                <ngx-help-support [expanded]="sidebarStatus.expanded"></ngx-help-support>
            </nb-sidebar-footer>
        </nb-sidebar>

        <!-- Page content -->
        <nb-layout-column
            class="main-content"
            id="main-content"
            [ngClass]="{'sidebar-expanded-margin': sidebarStatus.expanded}"
        >
            <router-outlet></router-outlet>
        </nb-layout-column>

        <!-- Page footer -->
        <nb-layout-footer
            *ngIf="sidebarStatus.isMainPage"
            [ngClass]="{'sidebar-expanded-margin': sidebarStatus.expanded}"
            class="footer-container"
        >
            <ngx-footer class="footer"></ngx-footer>
        </nb-layout-footer>
    </nb-layout>
</ng-container>
