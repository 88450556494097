import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, InfoBlock, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export interface StationInfo extends InfoBlock {}

export abstract class StationLoadForecastService {
    /*
     * Infrastructure: results for station(s)
     */
    abstract getAllStationsPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getStationInfo(workflowItemId: string, scenarioId: string, stationId: string): Observable<StationInfo[]>;

    abstract getStationViolationsByInfrastructureType(
        workflowItemId: string,
        scenarioId: string,
        stationId: string,
        seasonTiming: string,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld>;

    abstract getStationForecastByInfrastructureType(
        workflowItemId: string,
        scenarioId: string,
        stationId: string,
        seasonTiming: string,
        viewBy: string,
        filterViolations: boolean,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getStationBreakdownPivot(
        workflowItemId: string,
        scenarioId: string,
        stationId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getAllStationsPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getStationBreakdownPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        stationId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
