import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class ReportingApi {
    private readonly PREFIX = 'reporting';

    constructor(private api: HttpService) {}

    /*    public initSettingsByWorkflowItem(workflowItemId: string): Observable<LoadForecastSettings> {
        return this.api.get(`${this.PREFIX_SETTINGS}/${workflowItemId}`);
    }*/

    public getDataExtractPreview(
        workflowItemId: string,
        outputCategory: string,
        outputType: string,
    ): Observable<DataSource> {
        let params = `outputCategory=${outputCategory}`;
        params = params + `&outputType=${outputType}`;
        return this.api.getServerDataSource(`${this.PREFIX}/data-extract/${workflowItemId}?${params}`);
    }

    public getDataExtractCsv(
        workflowItemId: string,
        outputCategory: string,
        outputType: string,
        outputResult: string,
    ): Observable<APIResponse<DownloadURL>> {
        let params = `outputCategory=${outputCategory}`;
        params = params + `&outputType=${outputType}`;
        params = params + `&outputResult=${outputResult}`;
        return this.api.get(`${this.PREFIX}/data-extract/${workflowItemId}/csv?${params}`);
    }
}
