import {Component, Input} from '@angular/core';

export interface SimpleCodeNameSelection {
    code: string;
    name: string;
    selected: boolean;
}

@Component({
    selector: 'ngx-report-type-indicator',
    templateUrl: './report-type-indicator.component.html',
    styleUrls: ['./report-type-indicator.component.scss'],
})
export class ReportTypeIndicatorComponent {
    @Input() dataList: SimpleCodeNameSelection[];
}
