<div class="height-100 empty-chart">
    <div>
        <nb-card class="status-card">
            <nb-card-body class="chart-card">
                <ngx-icon-box class="chart-settings" icon="more-vertical-outline"></ngx-icon-box>
                <ngx-chart [options]="chartOptions"></ngx-chart>
            </nb-card-body>
        </nb-card>
    </div>
</div>
