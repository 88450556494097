<div class="chart-card-container">
    <nb-card class="chart-card" *ngFor="let number of iterArray">
        <nb-card-body class="chart-card-body">
            <ngx-icon-box class="chart-settings" icon="more-vertical-outline"></ngx-icon-box>
            <div class="chart-container">
                <ngx-chart [options]="options"></ngx-chart>
            </div>
        </nb-card-body>

        <nb-card-footer class="chart-card-footer">
            <div class="chart-footer-item" *ngFor="let number of [0, 1, 2]">
                <label class="footer-label-value">Loading</label>
            </div>
        </nb-card-footer>
    </nb-card>
</div>
