import {Component, Input} from '@angular/core';
import {EMPTY_CHART_COLOR} from '../empty-chart-config';

@Component({
    selector: 'ngx-empty-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyPieChartComponent {
    @Input() chartSettings = true;
    public options = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '5%',
            left: 'center',
        },
        xAxis: {
            show: false,
        },
        yAxis: {
            show: false,
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                label: {
                    show: false,
                    position: 'center',
                },
                data: [{value: 0}],
            },
        ],
        color: [EMPTY_CHART_COLOR.color],
        animation: false, // disable to loading animation
    };
}
