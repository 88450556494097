import {Component, Input} from '@angular/core';
import {EMPTY_CHART_COLOR} from '../empty-chart-config';

@Component({
    selector: 'ngx-empty-column-chart',
    templateUrl: './column-chart.component.html',
    styleUrls: ['./column-chart.component.scss'],
})
export class EmptyColumnChartComponent {
    @Input() chartSettings = true;
    textBackGroundColor = EMPTY_CHART_COLOR.color;
    barColor = EMPTY_CHART_COLOR.color;
    transparent = EMPTY_CHART_COLOR.transparent;

    xAxisDataTextStyle = {
        borderRadius: 3,
        borderColor: this.textBackGroundColor,
        backgroundColor: this.textBackGroundColor,
        color: this.transparent,
    };

    option = {
        title: {},
        tooltip: {
            trigger: 'axis',
        },
        grid: {},
        xAxis: [
            {
                type: 'category',
                data: [
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                    {
                        value: 'empty String',
                        textStyle: this.xAxisDataTextStyle,
                    },
                ],
            },
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                },
                axisLabel: {
                    color: this.transparent,
                    backgroundColor: this.textBackGroundColor,
                    borderRadius: 6,
                    fontSize: 20,
                    margin: 10,
                    formatter: '{ee}',
                },
            },
        ],
        series: [
            {
                name: 'empty String',
                type: 'bar',
                barWidth: '60%',
                data: [
                    {
                        value: 10,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 52,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 200,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 334,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 390,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 330,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 220,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 100,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                ],
            },
        ],
        animation: false, // disable to loading animation
    };
}
