import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';

@Component({
    template: `
        <div class="form-container">
            <nb-select fullWidth multiple [formControl]="selectControl" [placeholder]="placeholder">
                <nb-select-label>
                    {{ isAllSelected ? 'All' : getLabels(selectControl.value) }}
                </nb-select-label>
                <nb-option [value]="''" class="search">
                    <nb-form-field>
                        <input nbInput fullWidth [formControl]="filterControl" placeholder="Search..." />
                        <button nbSuffix nbButton ghost (click)="this.filterControl.reset()">
                            <nb-icon [icon]="'close'" pack="eva" [attr.aria-label]="'clear search field'"> </nb-icon>
                        </button>
                    </nb-form-field>
                </nb-option>
                <nb-option
                    *ngIf="options.length && options.length === (filteredOptions$ | async).length"
                    [value]="0"
                    (click)="toggleSelectAll()"
                    >Select All</nb-option
                >
                <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">
                    {{ option.label }}
                </nb-option>
            </nb-select>
            <span class="amount" *ngIf="selectControl?.value?.length">{{ selectControl.value.length }}</span>
        </div>
    `,
    styleUrls: ['./multi-select-filter.component.scss'],
})
export class MultiSelectFilterComponent extends DefaultFilter implements OnInit, OnChanges, OnDestroy {
    options: {value: string; label: string}[];
    placeholder: string;

    selectControl: FormControl = new FormControl([]);
    filterControl: FormControl = new FormControl();
    filteredOptions$: ReplaySubject<{value: string; label: string}[]> = new ReplaySubject(1);

    isAllSelected: boolean = false;

    _onDestroy = new Subject();

    constructor() {
        super();
    }

    ngOnInit() {
        this.selectControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                filter((value) => {
                    //when focusing on search input checkbox selects
                    //this removes search-input's value from this.selectControl
                    // and stops the process of subscription to this event
                    let isSearch = value.includes('');
                    if (isSearch) {
                        let _value = value.filter((item) => item !== '');
                        this.selectControl.setValue(_value, {emitEvent: false});
                    }

                    return !isSearch;
                }),
                map((value: any[]) => {
                    if (value && value.length === this.options.length && !value.includes(0)) {
                        let _value = [...value, 0];
                        this.isAllSelected = true;
                        this.selectControl.setValue(_value, {emitEvent: false});
                        return _value;
                    } else if (value && value.length !== this.options.length + 1) {
                        let _value = value.filter((item) => item !== 0);
                        this.isAllSelected = false;
                        this.selectControl.setValue(_value, {emitEvent: false});
                        return _value;
                    }
                }),
                debounceTime(this.delay),
            )
            .subscribe((value: any[]) => {
                if (!value || !value.length) {
                    this.query = '';
                    this.setFilter();
                } else if (this.selectControl.status === 'VALID') {
                    let result = '';
                    value.forEach((item) => {
                        if (item) {
                            if (result === '') result += `${item}`;
                            else result += `|${item}`;
                        }
                    });

                    this.query = result;
                    this.setFilter();
                }
            });

        this.options = this.column.filter.config?.options || [];
        this.placeholder = this.column.filter.config?.placeholder || 'Select...';

        this.filterControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value: string) => {
            value && this.selectControl.reset([]);
            this.filterOptions();
        });

        this.filteredOptions$.next(this.options);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.selectControl.setValue(this.selectControl.value);
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    toggleSelectAll() {
        if (this.selectControl.value.length >= this.options.length) {
            this.selectControl.reset([]);
            this.isAllSelected = false;
        } else {
            let _options = this.options.reduce((acc, curr) => {
                return [...acc, curr.value];
            }, []);
            this.selectControl.setValue([..._options, 0]);
            this.isAllSelected = true;
        }
    }

    protected filterOptions() {
        if (!this.options) {
            return;
        }

        let search = this.filterControl.value;
        if (!search) {
            this.filteredOptions$.next(this.options);
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOptions$.next(this.options.filter((opt) => opt.label.toLowerCase().indexOf(search) > -1));
    }

    getLabels(values: string[]) {
        let _labels = values?.reduce((acc, curr) => {
            if (acc !== '') acc += ', ';
            return (acc += this.options.find((option) => option.value === curr)?.label);
        }, '');
        return _labels || '';
    }
}
