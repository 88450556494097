import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'ngx-footer',
    template: `
        <label
            >ENGIN Version {{ version_major }}.{{ version_minor }}.{{ version_patch }} © {{ currentYear }} - Engineered
            Intelligence Inc.</label
        >
        <!--<label class="footer-logo"></label>-->
        <label></label>
    `,
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    version_major: number = environment.APP_VERSION_MAJOR;
    version_minor: number = environment.APP_VERSION_MINOR;
    version_patch: number = environment.APP_VERSION_PATCH;
    currentYear: string = moment(new Date()).format('YYYY');
}
