import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule} from '@nebular/auth';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {ChartsService, ColorsService, FontsService, FormatsService, TimezoneService} from './utils';

import {CommonBackendModule} from './backend/common/common-backend.module';
import {EnginBackendModule} from './backend/engin/engin-backend.module';
import {EnginErrorHandlerService} from './error-handling/engin-error-handler.service';

export const NB_CORE_PROVIDERS = [
    ...CommonBackendModule.forRoot().providers,

    ...EnginBackendModule.forRoot().providers,

    ChartsService,
    FormatsService,
    ColorsService,
    FontsService,
    TimezoneService,
];

@NgModule({
    imports: [CommonModule],
    exports: [NbAuthModule],
    declarations: [],
    providers: [EnginErrorHandlerService],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [...NB_CORE_PROVIDERS],
        };
    }
}
