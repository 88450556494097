<ngx-icon-box
    class="chart-settings"
    icon="more-vertical-outline"
    [nbPopover]="settings"
    nbPopoverTrigger="hover"
    nbPopoverPlacement="left"
>
</ngx-icon-box>

<ng-template #settings>
    <ngx-chart-settings-popup
        [unitOptions]="fullUnitOptions"
        [unitItem]="unitItem"
        [labelOptions]="labelOptions"
        [graphId]="graphId"
        (itemChanged)="changed($event)"
        [disableDataDownload]="disableDataDownload"
        [downloadFileName]="downloadFileName"
        [chartOptions]="chartOptions"
        [chartType]="chartType"
        [preparedDownloadData]="preparedDownloadData"
        [chartInstance]="chartInstance"
    >
    </ngx-chart-settings-popup>
</ng-template>
