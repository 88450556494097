<!--Use Chart Option-->
<span class="chart-tool-container abs-top-right" *ngIf="chartOptions && chartOptions.series">
    <span *ngFor="let item of chartOptions.series; let i = index" (click)="onClickToggleLegend($event, i, item.id)">
        <ng-container *ngIf="item.id">
            <span
                *ngIf="activeLegendSelection[item.id] && item.lineStyle"
                [ngStyle]="{background: item.lineStyle.color}"
                class="color-point"
            ></span>

            <span
                *ngIf="activeLegendSelection[item.id] && item.itemStyle && !item.lineStyle"
                [ngStyle]="{background: item.itemStyle.color}"
                class="color-point"
            ></span>

            <span *ngIf="!activeLegendSelection[item.id]" [ngStyle]="{background: 'grey'}" class="color-point"></span>
            <span class="legend-label">{{ item.name }}</span>
        </ng-container>
    </span>
</span>

<!--Use name and color only-->
<span class="chart-tool-container abs-top-right" *ngIf="legendColorArray">
    <span *ngFor="let item of legendColorArray; let i = index" (click)="onClickToggleLegend($event, i, item.name)">
        <span *ngIf="activeLegendSelection[item.name]" [ngStyle]="{background: item.color}" class="color-point"></span>

        <span *ngIf="!activeLegendSelection[item.name]" [ngStyle]="{background: 'grey'}" class="color-point"></span>

        <span class="legend-label">{{ item.name }}</span>
    </span>
</span>

<!---->
<!--*ngIf="!vm.activeLegendSelection.value[mainChartData.data.legend[i]]"-->
