import {Injectable} from '@angular/core';
import {DownloadURL, PeriodFilter} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DemandFactorApi} from '@core/backend/engin/api/load-forecast/demand-factor.api';
import {DemandFactorService} from '@core/interfaces/engin/load-forecast/demand-factor';

@Injectable()
export class DemandFactorServiceImpl extends DemandFactorService {
    constructor(private api: DemandFactorApi) {
        super();
    }

    /*
     * Demand factors
     */
    // Pivot data
    public getOrganicGrowthPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        return this.api.getOrganicGrowthPivot(workflowItemId, scenarioId, period, unit);
    }

    public getLoadTransferPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        return this.api.getLoadTransferPivot(workflowItemId, scenarioId, period, unit);
    }

    public getPhotovoltaicPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        return this.api.getPhotovoltaicPivot(workflowItemId, scenarioId, period, unit);
    }

    public getElectricVehiclePivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        return this.api.getElectricVehiclePivot(workflowItemId, scenarioId, period, unit);
    }

    public getLLAConnectionPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        return this.api.getLLAConnectionPivot(workflowItemId, scenarioId, period, unit);
    }

    public getLLADevelopPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource> {
        return this.api.getLLADevelopPivot(workflowItemId, scenarioId, period, unit);
    }

    // Data download
    public getOrganicGrowthPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getOrganicGrowthPivotCSV(workflowItemId, scenarioId, period, unit);
    }

    public getLoadTransferPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getLoadTransferPivotCSV(workflowItemId, scenarioId, period, unit);
    }

    public getPhotovoltaicPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getPhotovoltaicPivotCSV(workflowItemId, scenarioId, period, unit);
    }

    public getElectricVehiclePivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getElectricVehiclePivotCSV(workflowItemId, scenarioId, period, unit);
    }

    public getLLAConnectionPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getLLAConnectionPivotCSV(workflowItemId, scenarioId, period, unit);
    }

    public getLLADevelopPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getLLADevelopPivotCSV(workflowItemId, scenarioId, period, unit);
    }
}
