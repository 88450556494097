import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormatsService} from '../../../@core/utils';
import {User} from '../../../@core/interfaces/common/users';
import {NbToastrService} from '@nebular/theme';
import {getDeepFromObject, NB_AUTH_OPTIONS, NbAuthResult, NbAuthService} from '@nebular/auth';
import {Router} from '@angular/router';

@Component({
    selector: 'ngx-register',
    templateUrl: './register-user.component.html',
    styleUrls: ['./register-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterUserComponent {
    registerForm: FormGroup = this.fb.group({
        firstName: this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
        lastName: this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
        email: this.fb.control('', [Validators.required, Validators.pattern(FormatsService.EMAIL_PATTERN)]),
        password: this.fb.control('', [
            Validators.required,
            Validators.pattern(FormatsService.ONE_LOWERCASE_LETTER_PATTERN),
            Validators.pattern(FormatsService.ONE_UPPERCASE_LETTER_PATTERN),
            Validators.pattern(FormatsService.ONE_NUMBER_PATTERN),
            Validators.minLength(8),
            Validators.maxLength(50),
        ]),
        confirmPassword: this.fb.control('', [Validators.required]),
        terms: this.fb.control('', [Validators.required]),
    });

    constructor(
        private toasterService: NbToastrService,
        private fb: FormBuilder,
        private router: Router,
        private authService: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) private options = {},
    ) {}

    get userFromGroupValue(): User {
        return this.registerForm.value;
    }

    get firstNameFormControl(): FormControl {
        return this.registerForm.get('firstName') as FormControl;
    }

    get lastNameFormControl(): FormControl {
        return this.registerForm.get('lastName') as FormControl;
    }

    get emailFormControl(): FormControl {
        return this.registerForm.get('email') as FormControl;
    }

    get passwordFormControl(): FormControl {
        return this.registerForm.get('password') as FormControl;
    }

    get confirmPasswordFormControl(): FormControl {
        return this.registerForm.get('confirmPassword') as FormControl;
    }

    register(): void {
        const strategy = getDeepFromObject(this.options, 'forms.register.strategy', null);
        const user = this.userFromGroupValue;

        this.authService.register(strategy, user).subscribe((result: NbAuthResult) => {
            if (result.isSuccess()) {
                this.toasterService.success('', result.getMessages());
                const redirect = result.getRedirect();
                return this.router.navigateByUrl(redirect);
            } else {
                this.toasterService.danger('', result.getResponse().error.error);
            }
        });
    }
}
