import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataPrepTool, DataPrepType, GuideInfo, ProcessStepError} from '@core/interfaces/engin/data-preparation';
import {map} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class DataPreparationApi {
    private readonly prefix = 'data-prep';

    constructor(private api: HttpService) {}

    public listActiveTools(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list`);
    }

    public getDataPrepTool(toolId: number): Observable<APIResponse<DataPrepTool>> {
        return this.api.get(`${this.prefix}/${toolId}`);
    }

    public setupDataPrepTool(toolType: DataPrepType): Observable<APIResponse<DataPrepTool>> {
        return this.api.get(`${this.prefix}/setup/${toolType}`);
    }

    public validateNextStep(toolId: number, data: any): Observable<APIResponse<DataPrepTool>> {
        return this.api.put(`${this.prefix}/${toolId}/nextStep`, data);
    }

    public getGuideInfo(toolType: DataPrepType): Observable<APIResponse<GuideInfo[]>> {
        return this.api.get(`${this.prefix}/guide/${toolType}`);
    }

    public getStepIssueLog(
        toolId: number,
        stepId: number,
        fileKey: string,
    ): Observable<APIResponse<ProcessStepError[]>> {
        const params = fileKey ? `?trackingId=${fileKey}` : '';
        return this.api.get(`${this.prefix}/error/${stepId}${params}`);
    }

    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': 'application/octet-stream',
            },
        });
    }

    getValidation(toolId: number, stepId: number): Observable<any> {
        return this.api.get(`${this.prefix}/${toolId}/validate/${stepId}`).pipe(map((data) => data.response));
    }

    deleteStepDataFile(toolId: number, stepId: number, fileKey: string): Observable<APIResponse<DataPrepTool>> {
        const req = {
            fileKey: fileKey,
        };
        return this.api.post(`${this.prefix}/delete-file/${stepId}`, req);
    }

    delete(toolId: number): Observable<any> {
        return this.api.delete(`${this.prefix}/${toolId}`, {});
    }
}
