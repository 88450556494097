import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Dataset, DatasetCreator, DatasetService, DatasetType} from '../../../interfaces/common/dataset';
import {DatasetApi} from '../api/dataset.api';
import {APIResponse} from '../../../interfaces/system/system-common';

@Injectable()
export class DatasetServiceImpl extends DatasetService {
    constructor(private api: DatasetApi) {
        super();
    }

    findOne(id: string): Observable<APIResponse<Dataset>> {
        return this.api.findOne(id);
    }

    findMany(ids: string[]): Observable<APIResponse<Dataset[]>> {
        return this.api.findMany(ids);
    }

    create(data: DatasetCreator): Observable<APIResponse<Dataset>> {
        return this.api.create(data);
    }

    delete(uploadedId: string): Observable<APIResponse<boolean>> {
        return this.api.delete(uploadedId);
    }

    getLatestDatasetConfig(dataSetTypes: string): Observable<APIResponse<Dataset[]>> {
        return this.api.getLatestDatasetConfig(dataSetTypes);
    }

    reversePendingDelete(datasetId: string): Observable<APIResponse<boolean>> {
        return this.api.reversePendingDelete(datasetId);
    }

    listFiles(): Observable<DataSource> {
        return this.api.listFiles();
    }

    update(id: string, data: Dataset): Observable<APIResponse<Dataset>> {
        return this.api.update(id, data);
    }

    listLatestDataSet(pageSize: number, schemaType: string): Observable<Dataset[]> {
        return this.api.listLatestDataSet(pageSize, schemaType);
    }

    listFilesByType(dataType: DatasetType): Observable<DataSource> {
        return this.api.listFilesByType(dataType);
    }

    listFilesByTypeAndStatus(dataType: DatasetType, status: string): Observable<DataSource> {
        return this.api.listFilesByTypeAndStatus(dataType, status);
    }
}
