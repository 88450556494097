import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {RolesService} from '../../@core/interfaces/common/roles';

@Injectable()
export class RolesStore {
    readonly rolesList$: Observable<string[]> = this.rolesService.getList().pipe(shareReplay(1));

    constructor(private rolesService: RolesService) {}
}
