import {Component, Input, OnInit} from '@angular/core';
import {EMPTY_CHART_COLOR} from '../empty-chart-config';

@Component({
    selector: 'ngx-empty-pie-chart-donut',
    templateUrl: './pie-chart-donut.component.html',
    styleUrls: ['./pie-chart-donut.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyPieChartDonutComponent implements OnInit {
    @Input() count: number = 1;

    constructor() {}

    iterArray: number[] = [];

    ngOnInit() {
        for (let i = 0; i < this.count; i++) {
            this.iterArray.push(i);
        }
    }

    public options = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '5%',
            left: 'center',
        },
        xAxis: {
            show: false,
        },
        yAxis: {
            show: false,
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                label: {
                    show: false,
                    position: 'center',
                },
                data: [{value: 0}],
            },
        ],
        color: [EMPTY_CHART_COLOR.color],
        animation: false, // disable to loading animation
    };
}
