export const DARK_THEME = {
    name: 'dark',
    base: 'default',
    variables: {
        chartColors: [
            '#fd5735',
            '#ffaa00',
            '#ffd500',
            '#3db830',
            '#00b383',
            '#4ca5ff',
            '#ff708d',
            '#00b383',
            '#fad36d',
            '#424d69',
            '#c853ff',
            '#776eff',
            '#34cad3',
            '#a6aebd',
            '#ffd5de',
            '#b4e9db',
            '#fef3d5',
            '#eabeff',
            '#cae5ff',
            '#e9f4b1',
            '#ffe6b3',
            '#d6d3ff',
            '#c1eef2',
            '#e4e6eb',
            '#424d69',
            '#879ac3',
        ],
        optimizerColors: [
            '#f78279', // 0
            '#c00000', // 1
            '#bdd7ee', // 2
            '#0070c0', // 3
            '#20cd17', // 4
            '#a0ffa7', // 5
            '#ffc000', // 6
            '#fff0a0', // 7
            '#c00000', // 8
            '#f78279', // 9
            '#ff7c80', // 10
            '#ffccc0', // 11
        ],

        demandFactors: [
            '#579FE6', //0
            '#C2ECFF', //1
            '#00B383', //2
            '#D5F2C4', //3
            '#FAD36D', //4
            '#FFF8BF', //5
            '#FFB660', //6
            '#FFCF96', //7
            '#FF708D', //8
            '#FFC9D5', //9
        ],

        primary_default: '#fe9339',
        secondary_default: '#0c8ac7',
        warn_default: '#f0735b',

        fontColor: '#fff',
        fontHintColor: '#9fa9bd',
        backgroundChartColor: '#202840',
        backgroundBasicColor1: '#9fa9bd',
        tooltipBg: '#202840',

        axisNameText: '#c5ccdb',
        axisLine: '#6e707a',

        axisSliderBackground: '#202840', // color-basic-900
        axisSliderShadow: '#424d69', // color-basic-700
        axisSliderHandle: '#131729', // color-basic-1100
    },
};
