import {Injectable} from '@angular/core';
import {
    DownloadURL,
    InfrastructureForecastBySeasons,
    PeriodFilter,
    SimpleSeriesOld,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {VaultInfo, VaultLoadForecastService} from '@core/interfaces/engin/load-forecast/vault';
import {VaultLoadForecastApi} from '@core/backend/engin/api/load-forecast/vault.api';

@Injectable()
export class VaultLoadForecastServiceImpl extends VaultLoadForecastService {
    constructor(private api: VaultLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for vault(s)
     */
    public getVaultPivot(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getVaultPivot(workflowItemId, scenarioId, vaultId, period, seasonIds, unit, sensitivityId);
    }

    public getVaultPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getVaultPivotCSV(workflowItemId, scenarioId, vaultId, period, seasonIds, unit, sensitivityId);
    }

    public getVaultInfo(workflowItemId: string, scenarioId: string, vaultId: string): Observable<VaultInfo[]> {
        return this.api.getVaultInfo(workflowItemId, scenarioId, vaultId);
    }

    public getVaultForecastBySeasons(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons> {
        return this.api.getVaultForecastBySeasons(workflowItemId, scenarioId, vaultId, period, unit, sensitivityId);
    }

    public getVaultForecastByFactors(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getVaultForecastByFactors(workflowItemId, scenarioId, vaultId, period, unit, sensitivityId);
    }
}
