import {APP_BASE_HREF} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ThemeModule} from '@theme/theme.module';
import {CoreModule} from '@core/core.module';
import {AuthModule} from '@auth/auth.module';
import {StoreModule} from './@store';
import {PagesComponent} from './pages/pages.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {HeaderComponent} from '@theme/components/header/header.component';
import {SidebarActionComponent} from '@theme/components';
import {NbCardModule, NbIconModule, NbSelectModule, NbWindowModule} from '@nebular/theme';
import {HttpErrorInterceptor} from '@core/error-handling/http-error.interceptor';

@NgModule({
    declarations: [AppComponent, PagesComponent, DashboardComponent, HeaderComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        StoreModule.forRoot(),
        ThemeModule.forRoot(),
        AuthModule.forRoot(),
        CoreModule.forRoot(),
        NbCardModule,
        NbIconModule,
        NbWindowModule.forRoot(),
        NbSelectModule,
        ThemeModule,
    ],
    bootstrap: [AppComponent],
    entryComponents: [SidebarActionComponent],
    providers: [
        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    ],
})
export class AppModule {}
