<h1 id="title" class="title">Register</h1>

<nb-alert
    *ngIf="
        (confirmPasswordFormControl?.errors && confirmPasswordFormControl?.touched) ||
        (passwordFormControl?.errors && passwordFormControl.touched)
    "
    outline="danger"
    role="alert"
>
    <div class="strict">
        <label>Password must meet the following criteria:</label>
        <label>- 8 characters long</label>
        <label>- contain at least 1 number</label>
        <label>- contain at least 1 uppercase letter</label>
        <label>- contain at least 1 lowercase letter</label>
    </div>
</nb-alert>

<form [formGroup]="registerForm">
    <div class="input-row form-control-group">
        <label>First Name</label>
        <div class="input-column">
            <input
                nbInput
                fullWidth
                fieldSize="large"
                formControlName="firstName"
                [status]="
                    firstNameFormControl?.hasError('minlength') || firstNameFormControl?.hasError('maxlength')
                        ? 'danger'
                        : ''
                "
                placeholder="First Name"
            />

            <ngx-validation-message
                label="First Name"
                [showMinLength]="firstNameFormControl?.hasError('minlength') && firstNameFormControl.touched"
                [showMaxLength]="firstNameFormControl?.hasError('maxlength') && firstNameFormControl.touched"
                [showRequired]="firstNameFormControl?.errors?.required && firstNameFormControl.touched"
                [minLength]="3"
                [maxLength]="20"
            ></ngx-validation-message>
        </div>
    </div>

    <div class="input-row form-control-group">
        <label>Last Name</label>
        <div class="input-column">
            <input
                nbInput
                fullWidth
                fieldSize="large"
                formControlName="lastName"
                [status]="
                    lastNameFormControl?.hasError('minlength') || lastNameFormControl?.hasError('maxlength')
                        ? 'danger'
                        : ''
                "
                placeholder="Last Name"
            />

            <ngx-validation-message
                label="Last Name"
                [showMinLength]="lastNameFormControl?.hasError('minlength') && lastNameFormControl.touched"
                [showMaxLength]="lastNameFormControl?.hasError('maxlength') && lastNameFormControl.touched"
                [showRequired]="lastNameFormControl?.errors?.required && lastNameFormControl.touched"
                [minLength]="3"
                [maxLength]="20"
            ></ngx-validation-message>
        </div>
    </div>
    <div class="input-row form-control-group">
        <label>Email</label>
        <div class="input-column">
            <input
                nbInput
                fullWidth
                fieldSize="large"
                formControlName="email"
                [status]="emailFormControl?.hasError('pattern') ? 'danger' : ''"
                placeholder="Email"
            />

            <ngx-validation-message
                label="Email"
                [showPattern]="emailFormControl?.hasError('pattern') && emailFormControl.touched"
                [showRequired]="emailFormControl?.errors?.required && emailFormControl.touched"
            ></ngx-validation-message>
        </div>
    </div>
    <div class="input-row form-control-group">
        <label>Password</label>
        <div class="input-column">
            <input
                nbInput
                fullWidth
                fieldSize="large"
                type="password"
                formControlName="password"
                autocomplete="false"
                [status]="
                    passwordFormControl?.hasError('minLength') ||
                    passwordFormControl?.hasError('pattern') ||
                    passwordFormControl?.hasError('maxLength')
                        ? 'danger'
                        : ''
                "
                placeholder="Password"
            />
            <ngx-validation-message
                label="Password"
                [showPattern]="passwordFormControl?.hasError('pattern') && passwordFormControl.touched"
                [showRequired]="passwordFormControl?.errors?.required && passwordFormControl.touched"
            ></ngx-validation-message>
        </div>
    </div>
    <div class="input-row form-control-group">
        <label>Confirm password</label>
        <div class="input-column">
            <input
                nbInput
                fullWidth
                fieldSize="large"
                type="password"
                formControlName="confirmPassword"
                autocomplete="false"
                [status]="
                    confirmPasswordFormControl?.hasError('minLength') ||
                    confirmPasswordFormControl?.hasError('maxLength')
                        ? 'danger'
                        : ''
                "
                placeholder="Confirm password"
            />
            <ngx-validation-message
                label="Confirm password"
                [showRequired]="confirmPasswordFormControl?.errors?.required && confirmPasswordFormControl.touched"
            >
            </ngx-validation-message>
            <p class="error-message" *ngIf="passwordFormControl?.value !== confirmPasswordFormControl?.value">
                Password and confirm password does not match!
            </p>
        </div>

        <div class="form-control-group accept-group">
            <nb-checkbox name="terms" formControlName="terms">
                Agree to <a href="#" target="_blank"><strong>Terms & Conditions</strong></a>
            </nb-checkbox>
        </div>
    </div>

    <button
        nbButton
        fullWidth
        status="primary"
        size="large"
        [disabled]="!registerForm.valid || passwordFormControl?.value !== confirmPasswordFormControl?.value"
        (click)="register()"
    >
        Register
    </button>
</form>

<section class="another-action" aria-label="Sign in">
    Already have an account? <a class="text-link" routerLink="../login">Log in</a>
</section>
