import {Injectable} from '@angular/core';
import {DownloadURL, PeriodFilter} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries, SimpleSeries} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {MeterLoadForecastApi} from '../../api/load-forecast/meter.api';
import {LoadProfileInfo, MeterInfo, MeterLoadForecastService} from '@core/interfaces/engin/load-forecast/meter';

@Injectable()
export class MeterLoadForecastServiceImpl extends MeterLoadForecastService {
    constructor(private api: MeterLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for meters(s)
     */
    public getMeterInfo(workflowItemId: string, scenarioId: string, meterId: string): Observable<MeterInfo[]> {
        return this.api.getMeterInfo(workflowItemId, scenarioId, meterId);
    }

    public getMeterPivot(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getMeterPivot(workflowItemId, scenarioId, meterId, period, seasonIds, unit, sensitivityId);
    }

    public getMeterPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getMeterPivotCSV(workflowItemId, scenarioId, meterId, period, seasonIds, unit, sensitivityId);
    }

    public getLoadProfileInfo(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
    ): Observable<LoadProfileInfo> {
        return this.api.getLoadProfileInfo(workflowItemId, scenarioId, meterId);
    }

    public getLoadProfileRaw(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
    ): Observable<SimpleSeries<Date, number>> {
        return this.api.getLoadProfileRaw(workflowItemId, scenarioId, meterId);
    }

    public getLoadProfileTypicalDay(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
        groupBy: string,
    ): Observable<SimpleMultiSeries<number>> {
        return this.api.getLoadProfileTypicalDay(workflowItemId, scenarioId, meterId, groupBy);
    }
}
