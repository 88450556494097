import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'ngx-chart-legend-bar',
    templateUrl: './chart-legend-bar.component.html',
    styleUrls: ['./chart-legend-bar.component.scss'],
})
export class ChartLegendBarComponent implements OnInit {
    // Different options for receiving legend labels and colors
    @Input() chartOptions; // options: {legend: selected: []}, series: [ {id: ... }]
    @Input() legendColorArray; // array of {name: '', color: ''}
    // Generate both sets of outputs which may be used depending on requirements
    @Output() outputOption = new EventEmitter(); // Used to replace active selections in chart options
    @Output() outputArray = new EventEmitter(); // General purpose (e.g., change graph title)

    constructor() {}

    public activeLegendSelection = {};

    ngOnInit() {
        const selected = {};
        // If user provided chart options
        if (this.chartOptions != null) {
            this.chartOptions.series.forEach((item, index) => {
                selected[item.id] = true;
            });
        }

        // Else user has provided set of legend labels/colors
        if (this.legendColorArray != null) {
            this.legendColorArray.forEach((item, index) => {
                selected[item.name] = true;
            });
        }

        this.activeLegendSelection = selected;
    }

    onClickToggleLegend(event, index, name) {
        this.activeLegendSelection[name] = !this.activeLegendSelection[name];
        this.outputOption.emit(this.activeLegendSelection);

        const selectedLegend = [];
        for (const [key, value] of Object.entries(this.activeLegendSelection)) {
            if (value) {
                selectedLegend.push(key);
            }
        }
        this.outputArray.emit(selectedLegend);
    }
}
