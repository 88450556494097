import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export abstract class SystemLoadForecastService {
    /*
     * Infrastructure: results for system
     */

    // System pages
    abstract getSystemForecastBySeasons(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getSystemForecastBreakdown(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getSystemForecastBreakdownCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getSystemViolationsByInfrastructureType(
        workflowItemId: string,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld>;

    abstract getSystemViolationsPivot(
        workflowItemId: string,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getSystemViolationsPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getSystemViolationBreakdown(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getSystemViolationBreakdownPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getSystemViolationBreakdownPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    // Meta-analysis pages
    abstract getSystemForecastByFactors(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getSystemForecastPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getSystemForecastPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
