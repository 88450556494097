import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-chart-title-bar',
    templateUrl: './chart-title-bar.component.html',
    styleUrls: ['./chart-title-bar.component.scss'],
})
export class ChartTitleBarComponent implements OnInit {
    @Input() title: string;
    @Input() combinationTitle: string[];
    @Input() defaultTitle;

    @Input() subTitle: string;

    // ========

    constructor() {}

    ngOnInit() {
        // for all legends are unselected
        if (this.combinationTitle != null) {
            this.defaultTitle = this.combinationTitle.join('&');
        }
    }
}
