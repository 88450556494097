import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Observable} from 'rxjs';
import {DatasetType} from './dataset';
import {APIResponse} from '../system/system-common';

const dataFileStatusMap = {CREATED: 'Created', VALID: 'Valid', INVALID: 'Invalid'} as const;
export type DATA_FILE_STATUS = keyof typeof dataFileStatusMap;

const fileFormatMap = {CSV: 'CSV', MS_EXCEL: 'Ms Excel'} as const;
export type FILE_FORMAT = keyof typeof fileFormatMap;

export interface DataFile {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    fileFormat: string;
    fileName: string;
    bucketName: string;
    fileKey: string;
    tableName: string;
    tableRows: string;
    schemaVersion: number;
    datasetType: string;
    status: DATA_FILE_STATUS;
    uploadErrorInfoList: UploadsErrorInfo[];
}

export interface DataUpload {
    id: string;
    uploadDate: string;
    status: {
        dataLoaded: boolean;
        status: string;
        info: string;
    };
    type: {
        uploadType: string;
        dataType: string;
    };
    archive: {
        fileName: string;
        fileKey: string;
    };
    info: any;
}

export interface UploadsErrorInfo {
    id: number;
    createdOn: string;
    updatedOn?: any;
    uploadId?: number;
    uploadErrorType: string;
    uploadErrorMessage: string;
    tableName: string;
    columnName: string;
    errorCount: number;
}

export interface UploadFileCreate {
    fileName: string;
    bucketName: string;
    fileKey: string;
    tableName: string;
    fileFormat: FILE_FORMAT;
    schemaType: DatasetType;
}

export interface PreSingedURL {
    bucketName: string;
    fileKey: string;
    url: string;
}

export abstract class DataFileService {
    abstract uploadToPresignedUrl(response: string, formData: File, fileFormat: FILE_FORMAT);

    abstract findOne(id: string): Observable<APIResponse<DataFile>>;

    abstract getPresignedUrl(
        fileName: string,
        directory: string,
        fileFormat: FILE_FORMAT,
    ): Observable<APIResponse<PreSingedURL>>;

    abstract verifyUpload(fileName: string): Observable<APIResponse<any>>;

    abstract create(data: UploadFileCreate): Observable<APIResponse<DataFile>>;

    abstract process(uploadedId: string): Observable<APIResponse<DataFile>>;

    abstract listFiles(): Observable<DataSource>;

    abstract listFilesBySchema(schemaType: DatasetType, status: string): Observable<DataSource>;

    abstract downloadFileByIds(id: number[]): Observable<APIResponse<string[]>>;
}
