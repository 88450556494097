import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PagesStore} from '../../../@store';

@Component({
    selector: 'ngx-table-settings-popup',
    templateUrl: './table-settings-popup.component.html',
    styleUrls: ['./table-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSettingsPopupComponent {
    /*
     * Data binding for unit selector functionality
     * - unitOptions: {label, value}[] for expected unit options
     * - unitItem: selected unit
     */
    @Input() unitOptions: {label: string; value: string}[];
    @Input() unitItem: any;
    @Input() graphId: string;
    @Output() itemChanged = new EventEmitter<any>();
    // Data download component is enabled by default
    @Input() disableDataDownload?: boolean;
    @Output() downloadFile = new EventEmitter();

    constructor(public pagesStore: PagesStore) {}

    dataDownloadCSV() {
        this.downloadFile.emit();
    }
}
