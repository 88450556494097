import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StudyCollection} from '../../../@core/interfaces/engin/jobs';

@Component({
    selector: 'ngx-study-collection-selector',
    templateUrl: './study-collection-selector.component.html',
    styleUrls: ['./study-collection-selector.component.scss'],
})
export class StudyCollectionSelectorComponent {
    // Component inputs
    @Input() title: string;
    @Input() studyCollections: StudyCollection[];
    // Component outputs/events
    @Output() studyChange: EventEmitter<string> = new EventEmitter<string>();

    // Component internal data
    activeCollection: StudyCollection;
    activeWorkflowItemId: string;

    onStudyCollectionChange(selectedColId) {
        this.activeWorkflowItemId = '';
        this.activeCollection = this.studyCollections.filter((col) => col.id === selectedColId)[0];
    }

    onStudySelected(selectedStudyId) {
        this.activeWorkflowItemId = selectedStudyId;
        this.studyChange.emit(this.activeWorkflowItemId);
    }
}
