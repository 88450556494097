import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {NbAuthComponent, NbLogoutComponent, NbRequestPasswordComponent, NbResetPasswordComponent} from '@nebular/auth';
import {AuthGuard} from '@auth/auth.guard';
import {PagesComponent} from './pages/pages.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from '@theme/components/login-form/login.component';

const config: ExtraOptions = {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy',
};

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: PagesComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'user-management',
                loadChildren: () => import('./pages/users/user-management.module').then((m) => m.UserManagementModule),
            },
            {
                path: 'study-history',
                loadChildren: () =>
                    import('./pages/study-history/study-history.module').then((m) => m.StudyHistoryModule),
            },
            {
                path: 'data-management',
                loadChildren: () =>
                    import('./pages/data-management/data-management.module').then((m) => m.DataManagementModule),
            },
            {
                path: 'load-forecast',
                loadChildren: () =>
                    import('./pages/load-forecast/load-forecast.module').then((m) => m.LoadForecastModule),
            },
            {
                path: 'data-preparation',
                loadChildren: () =>
                    import('./pages/data-preparation/data-preparation.module').then((m) => m.DataPreparationModule),
            },
            {
                path: 'study-history',
                loadChildren: () =>
                    import('./pages/study-history/study-history.module').then((m) => m.StudyHistoryModule),
            },
            {
                path: 'data-prep-tool',
                loadChildren: () =>
                    import('./pages/data-preparation/data-preparation.module').then((m) => m.DataPreparationModule),
            },
            {
                path: 'reporting',
                loadChildren: () => import('./pages/reporting-tool/reporting.module').then((m) => m.ReportingModule),
            },
        ],
    },
    {
        path: 'auth',
        component: NbAuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'login',
                component: LoginComponent,
            },
            /*
            {
              path: 'register',
              component: RegisterUserComponent,
            },*/
            {
                path: 'logout',
                canActivate: [AuthGuard],
                component: NbLogoutComponent,
            },
            {
                path: 'request-password',
                component: NbRequestPasswordComponent,
            },
            {
                path: 'reset-password',
                component: NbResetPasswordComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
