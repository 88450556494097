import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export interface ScenarioInfo {
    id: string;
    name: string;
    description: string;
}

export abstract class ScenarioLoadForecastService {
    /*
     * Scenario comparison
     */
    abstract getScenarioPivot(
        workflowItemId: string,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getScenarioForecast(
        workflowItemId: string,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getScenarioInfo(workflowItemId: string): Observable<ScenarioInfo[]>;

    abstract getScenarioPivotCSV(
        workflowItemId: string,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    /*
     * Other
     */
    abstract getCustomPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        factor: string[],
        pivotBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getCustomPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        factor: string[],
        pivotBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
