<div class="chart-card-container mb-5">
    <nb-card class="empty-chart-card">
        <nb-card-header class="chart-card-header">
            <div class="chart-header">
                <ngx-icon-box class="chart-settings" *ngIf="chartSettings" icon="more-vertical-outline"></ngx-icon-box>
                <label class="chart-title">Chart Title</label>
                <label class="chart-subtitle">Chart Subtitle</label>
            </div>
        </nb-card-header>

        <nb-card-body class="chart-card-body">
            <div class="chart-container">
                <ngx-chart [options]="option"></ngx-chart>
            </div>
        </nb-card-body>
    </nb-card>
</div>
