import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries, SimpleSeries} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, InfoBlock, InfrastructureForecastBySeasons, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export interface MeterInfo extends InfoBlock {}

export interface LoadProfileInfo {
    analysisInfo: InfoBlock[];
    metadataInfo: InfoBlock[];
}

export abstract class MeterLoadForecastService {
    /*
     * Infrastructure: results for meter(s)
     */
    abstract getMeterInfo(workflowItemId: string, scenarioId: string, meterId: string): Observable<MeterInfo[]>;

    abstract getMeterPivot(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getMeterPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getLoadProfileInfo(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
    ): Observable<LoadProfileInfo>;

    abstract getLoadProfileRaw(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
    ): Observable<SimpleSeries<Date, number>>;

    abstract getLoadProfileTypicalDay(
        workflowItemId: string,
        scenarioId: string,
        meterId: string,
        groupBy: string,
    ): Observable<SimpleMultiSeries<number>>;
}
