import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, share, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {UsersStore} from './users.store';
import {decodeJwtPayload} from '@nebular/auth';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Workflow, WorkflowItem, WorkflowService} from '@core/interfaces/engin/workflow';
import {APIResponse} from '@core/interfaces/system/system-common';
import {JobsService} from '@core/interfaces/engin/jobs';
import {User} from '@core/interfaces/common/users';

@Injectable()
export class StudiesStore extends Unsubscribable {
    private latestAsDefault = new BehaviorSubject<boolean>(null);
    readonly latestAsDefault$: Observable<boolean> = this.latestAsDefault.asObservable();

    private defaultWorkflowItemId = new BehaviorSubject<string>(null);
    readonly defaultWorkflowItemId$: Observable<string> = this.defaultWorkflowItemId.asObservable();

    private defaultWorkflowId = new BehaviorSubject<string>(null);
    readonly defaultWorkflowId$: Observable<string> = this.defaultWorkflowId.asObservable();

    private activeWorkflowId = new BehaviorSubject<string>(null);
    readonly activeWorkflowId$: Observable<string> = this.activeWorkflowId.asObservable();

    readonly activeWorkflow$: Observable<Workflow> = this.activeWorkflowId$.pipe(
        filter((d) => !!d),
        switchMap((collectionId: string) => {
            return this.workflowService.getWorkflowById(parseInt(collectionId, 10));
        }),
        map((resp: APIResponse<Workflow>) => {
            return resp.response;
        }),
        tap((collection: Workflow) => {
            const defaultWorkflowItem = collection.workflowItems
                ? collection.workflowItems.filter((workflowItem) => workflowItem.defaultItem)[0]
                : {};
            let workflowItemId: string;
            if (defaultWorkflowItem && defaultWorkflowItem[0] && defaultWorkflowItem[0].id) {
                workflowItemId = defaultWorkflowItem[0].id.toString();
            } else if (collection.workflowItems && collection.workflowItems[0] && collection.workflowItems[0].id) {
                workflowItemId = collection.workflowItems[0].id.toString();
            }
            // Reset the activeWorkflowItemId only if it has changed; else it will trigger unnecessary change detection
            if (!(this.activeWorkflowItemId.value === workflowItemId)) {
                this.setActiveWorkflowItemId(workflowItemId);
            }
        }),
    );

    private activeWorkflowItemId = new BehaviorSubject<string>(null);
    readonly activeWorkflowItemId$: Observable<string> = combineLatest<Observable<string>, Observable<Workflow>>([
        this.activeWorkflowItemId.asObservable(),
        this.activeWorkflow$,
    ]).pipe(
        map(([studyId, collection]: [string, Workflow]) => {
            // retrieval of studyId is dependent upon activeCollection, which is where studyId gets set
            return studyId;
        }),
        debounceTime(1000), // wait momentarily after study change
    );

    readonly activeWorkflowItem$: Observable<WorkflowItem> = this.activeWorkflowItemId$.pipe(
        switchMap((studyId: string) => {
            if (studyId) {
                return this.workflowService.getWorkflowItemById(parseInt(studyId, 10));
            }
        }),
        map((resp: APIResponse<WorkflowItem>) => {
            return resp.response;
        }),
    );

    private defaultStudyStrategy$ = this.usersStore.currentUser$.pipe(
        filter((d) => !!d),
        map((user: User) => {
            let defaultWorkflowId;
            this.workflowService.getLatestCompletedWorkFlow(true).subscribe((resp) => {
                if (this.latestAsDefault.value !== user.studyStrategy.latestAsDefault) {
                    this.latestAsDefault.next(user.studyStrategy.latestAsDefault);
                }
                let defaultWorkflowItemId;
                // Get defaultWorkflowId, optionally get defaultWorkflowItemId
                if (user.studyStrategy.latestAsDefault) {
                    const workflow: Workflow = resp.response;

                    defaultWorkflowId = workflow ? workflow.id.toString() : 'none';
                    const defaultStudy = workflow
                        ? workflow.workflowItems.filter((workflowItem) => workflowItem.defaultItem)
                        : null;
                    defaultWorkflowItemId = defaultStudy ? defaultStudy[0].id.toString() : 'none';
                } else {
                    defaultWorkflowId = user.studyStrategy.defaultWorkflowId;
                }

                this.setDefaultWorkflowId(defaultWorkflowId);
                this.initActiveWorkflowId(localStorage.getItem('active_workflow') || defaultWorkflowId, user.id);
                this.setActiveWorkflowItemId(localStorage.getItem('active_item') || defaultWorkflowItemId);
            });
            return defaultWorkflowId;
        }),
        share(),
    );

    constructor(
        private usersStore: UsersStore,
        private jobsData: JobsService,
        private workflowService: WorkflowService,
        private toastrService: NbToastrService,
    ) {
        super();
        combineLatest<Observable<string>, Observable<string>, Observable<string>, Observable<any>>([
            this.activeWorkflowId$,
            this.defaultWorkflowItemId$,
            this.defaultWorkflowId$,
            this.defaultStudyStrategy$,
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ([activeWorkflowId, defaultWorkflowItemId, defaultWorkflowId, strategy]: [
                    string,
                    string,
                    string,
                    any,
                ]) => {
                    if (defaultWorkflowId === 'none') {
                        this.toastrService.warning(
                            '',
                            'There are no studies completed. ' +
                                'Please select default study on Study History page or upload input data for start new Study.',
                        );
                    }
                },
            );
    }

    setDefaultWorkflowId(workflowId: string) {
        this.defaultWorkflowId.next(workflowId);
    }

    private dataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly dataLoading$: Observable<boolean> = this.dataLoading.asObservable();

    initActiveWorkflowId(workflowId: string, userId: string) {
        const currentActiveWorkflowId: string = localStorage.getItem('active_workflow');
        // No action if current collection is defined and same as new collection
        if (currentActiveWorkflowId == null || currentActiveWorkflowId !== workflowId) {
            localStorage.setItem('active_workflow', workflowId);
            this.dataLoading.next(true);
            this.workflowService
                .updateActiveWorkflow(parseInt(workflowId, 10), userId, true)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                    this.dataLoading.next(false);
                    this.activeWorkflowId.next(workflowId);
                });
        } else {
            this.activeWorkflowId.next(workflowId);
        }
    }

    updateActiveWorkflowId(workflowId: string) {
        const currentActiveWorkflowId: string = localStorage.getItem('active_workflow');
        const jwtPayload = decodeJwtPayload(localStorage.getItem('auth_app_token'));
        const userId = jwtPayload.id;
        localStorage.setItem('active_workflow', workflowId);

        if (currentActiveWorkflowId == null) {
            this.initActiveWorkflowId(workflowId, userId);
        } else {
            this.dataLoading.next(true);
            this.workflowService
                .updateActiveWorkflow(parseInt(currentActiveWorkflowId, 10), userId, false)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res) => {
                    this.workflowService
                        .updateActiveWorkflow(parseInt(workflowId, 10), userId, true)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((res2) => {
                            this.dataLoading.next(false);
                            this.activeWorkflowId.next(workflowId);
                        });
                });
        }
    }

    setActiveWorkflowItemId(workflowItemId: string) {
        localStorage.setItem('active_item', workflowItemId);
        this.activeWorkflowItemId.next(workflowItemId);
    }

    clearActiveWorkflowId() {
        const workflowId = localStorage.getItem('active_workflow');
        const jwtPayload = decodeJwtPayload(localStorage.getItem('auth_app_token'));
        const userId = jwtPayload.id;
        localStorage.removeItem('active_workflow');
        localStorage.removeItem('active_item');

        this.workflowService
            .updateActiveWorkflow(parseInt(workflowId, 10), userId, false)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                this.activeWorkflowId.next(null);
            });
    }

    updateDefaultStudyStrategy(value: boolean) {
        const ret = this.jobsData.updateDefaultStudyStrategy(value, this.defaultWorkflowId.value);
        this.usersStore.updateUserDefaultStudyStrategy(value, this.defaultWorkflowId.value); // force user data refresh to fetch the changes
        return ret;
    }

    public getActiveWorkflowItemId() {
        return this.activeWorkflowId.value;
    }
}
