<nb-radio-group
    [(ngModel)]="curValue"
    (valueChange)="changeValue($event)"
    name="ngx-inline-ratio-group"
    [formControlName]="formControlName"
>
    <nb-radio *ngFor="let option of options.sort(customCompare)" [value]="option.value">
        {{ option.title }}
    </nb-radio>
</nb-radio-group>
