import {EventEmitter, HostListener, OnDestroy, OnInit, Output, Directive, Input} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

/**
 * Directive to throttle subsequent click actions i.e. prevent multiple clicks before throttletime is exceeded.
 * - throttleTime fires action right away and prevents subsequent vs. debounceTime waits before firing action
 * Source: https://stackoverflow.com/questions/51390476/how-to-prevent-double-click-in-angular
 *
 * ### Usage
 * <button
 *  throttleClick
 *  (throttleClick)="fn()"
 * </button>
 */
@Directive({
    selector: '[ngxThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
    @Input() throttleTime: number = 500;
    @Output() throttleClick = new EventEmitter();

    private clicks = new Subject();
    private subscription: Subscription;

    constructor() {}

    ngOnInit() {
        this.subscription = this.clicks
            .pipe(throttleTime(this.throttleTime))
            .subscribe((e) => this.emitThrottledClick(e));
    }

    emitThrottledClick(e) {
        this.throttleClick.emit(e);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }
}
