export const environment = {
    APP_VERSION_MAJOR: 1,
    APP_VERSION_MINOR: 1,
    APP_VERSION_PATCH: 2,

    production: false,
    staging: false, // temporary for demos

    NODE_SERVER_URL: 'https://api-node.dev.lf.engincloud.com/api',
};
