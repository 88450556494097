import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ScenarioInfo} from '@core/interfaces/engin/load-forecast/scenario';

@Injectable()
export class ScenarioLoadForecastApi {
    private readonly PREFIX_SCENARIO = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Scenario comparison
     */
    public getScenarioPivot(
        workflowItemId: string,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `seasonTiming=${seasonTiming}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(`${this.PREFIX_SCENARIO}/${workflowItemId}/scenarios/pivot?${params}`);
    }

    public getScenarioForecast(
        workflowItemId: string,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(`${this.PREFIX_SCENARIO}/${workflowItemId}/scenarios/forecast`, {
            params: new HttpParams()
                .set('seasonTiming', seasonTiming)
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getScenarioInfo(workflowItemId: string): Observable<ScenarioInfo[]> {
        return this.api.get(`${this.PREFIX_SCENARIO}/${workflowItemId}/scenarios/info`);
    }

    public getScenarioPivotCSV(
        workflowItemId: string,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(`${this.PREFIX_SCENARIO}/${workflowItemId}/scenarios/pivot/csv`, {
            params: new HttpParams()
                .set('seasonTiming', seasonTiming)
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    /*
     * Other
     */
    public getCustomPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        factors: string[],
        pivotBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${minYear}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&factor=${factors.join(',')}`;
        params = params + `&pivotBy=${pivotBy}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_SCENARIO}/${workflowItemId}/scenarios/${scenarioId}/pivot?${params}`,
        );
    }

    public getCustomPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        factors: string[],
        pivotBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        const minYear: string = Math.max(Number(period.current) + 1, Number(period.min)) + '';
        return this.api.get(`${this.PREFIX_SCENARIO}/${workflowItemId}/scenarios/${scenarioId}/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', minYear)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('factor', factors.join(','))
                .set('pivotBy', pivotBy)
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }
}
