import {Injectable} from '@angular/core';
import {
    DownloadURL,
    InfrastructureForecastBySeasons,
    PeriodFilter,
    SimpleSeriesOld,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {FeederInfo, FeederLoadForecastService} from '@core/interfaces/engin/load-forecast/feeder';
import {FeederLoadForecastApi} from '@core/backend/engin/api/load-forecast/feeder.api';

@Injectable()
export class FeederLoadForecastServiceImpl extends FeederLoadForecastService {
    constructor(private api: FeederLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for feeder(s)
     */
    public getFeederPivot(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getFeederPivot(workflowItemId, scenarioId, feederId, period, seasonIds, unit, sensitivityId);
    }

    public getFeederPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getFeederPivotCSV(workflowItemId, scenarioId, feederId, period, seasonIds, unit, sensitivityId);
    }

    public getFeederInfo(workflowItemId: string, scenarioId: string, feederId: string): Observable<FeederInfo[]> {
        return this.api.getFeederInfo(workflowItemId, scenarioId, feederId);
    }

    public getFeederForecastBySeasons(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons> {
        return this.api.getFeederForecastBySeasons(workflowItemId, scenarioId, feederId, period, unit, sensitivityId);
    }

    public getFeederForecastByFactors(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getFeederForecastByFactors(workflowItemId, scenarioId, feederId, period, unit, sensitivityId);
    }

    public getFeederInfrastructureList(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
    ): Observable<DataSource> {
        return this.api.getFeederInfrastructureList(workflowItemId, scenarioId, feederId);
    }

    public getFeederInfrastructureListCSV(
        workflowItemId: string,
        scenarioId: string,
        feederId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getFeederInfrastructureListCSV(workflowItemId, scenarioId, feederId);
    }
}
