import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    DownloadURL,
    InfrastructureForecastBySeasons,
    PeriodFilter,
    SimpleSeriesOld,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {VaultInfo} from '@core/interfaces/engin/load-forecast/vault';

@Injectable()
export class VaultLoadForecastApi {
    private readonly PREFIX_VAULT = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Infrastructure: results for vault(s)
     */
    public getVaultPivot(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&filterByseasonId_IN=${seasonIds.join('|')}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_VAULT}/${workflowItemId}/scenarios/${scenarioId}/vaults/${vaultId}/pivot?${params}`,
        );
    }

    public getVaultPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_VAULT}/${workflowItemId}/scenarios/${scenarioId}/vaults/${vaultId}/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('filterByseasonId_IN', seasonIds.join('|'))
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getVaultInfo(workflowItemId: string, scenarioId: string, vaultId: string): Observable<VaultInfo[]> {
        return this.api.get(`${this.PREFIX_VAULT}/${workflowItemId}/scenarios/${scenarioId}/vaults/${vaultId}/info`);
    }

    public getVaultForecastBySeasons(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons> {
        return this.api.get(
            `${this.PREFIX_VAULT}/${workflowItemId}/scenarios/${scenarioId}/vaults/${vaultId}/seasons`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getVaultForecastByFactors(
        workflowItemId: string,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(
            `${this.PREFIX_VAULT}/${workflowItemId}/scenarios/${scenarioId}/vaults/${vaultId}/factors`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }
}
