<div class="chart-card-container mb-5">
    <nb-card class="chart-card">
        <nb-card-header class="chart-card-header">
            <div class="chart-header">
                <label class="chart-title">Economic Demographics</label>
                <label class="chart-subtitle">Time Until Optimal</label>
            </div>
        </nb-card-header>

        <nb-card-body class="chart-card-body">
            <div class="chart-container">
                <ngx-chart [options]="options"></ngx-chart>
            </div>
        </nb-card-body>
    </nb-card>
</div>
