import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';

@Component({
    selector: 'ngx-status-cell',
    template: `
        <div class="status-cell" *ngIf="icon && iconStatus && iconLabel">
            <span class="icon-wrapper" [ngClass]="[iconStatus]">
                <nb-icon pack="eva" [icon]="icon"></nb-icon>
            </span>
            <span class="status-label">{{ iconLabel }}</span>
        </div>
    `,
    styleUrls: ['./status-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCellComponent implements ViewCell, OnInit, OnChanges {
    icon: string;
    iconStatus: string;
    iconLabel: string;

    @Input() value: any;
    @Input() rowData: any;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.changeStatus();
    }

    ngOnChanges(): void {
        this.changeStatus();
    }

    /*
     * Used across entire data management tool for data files, datasets, and snapshots.
     * - Data file: Started, Uploaded, Success, Failed
     * - Dataset: ...
     * - Snapshot: ...
     */
    changeStatus(): void {
        switch (this.value) {
            // Data file: upload started, no text or icon
            case 'STARTED':
                this.icon = null;
                this.iconStatus = null;
                this.iconLabel = null;
                break;
            // Data file: upload status for a file uploaded
            case 'UPLOADED':
                this.icon = 'clock-outline';
                this.iconStatus = 'warning';
                this.iconLabel = 'In Progress';
                break;
            // Data file: created, pending validation
            // Dataset / snapshot: created
            case 'CREATED':
                this.icon = 'clock-outline';
                this.iconStatus = 'warning';
                this.iconLabel = 'Created';
                break;
            // Data prep tool: step is active means ready for user interaction
            case 'ACTIVE':
                this.icon = 'checkmark-circle-2-outline';
                this.iconStatus = 'basic';
                this.iconLabel = 'Waiting for Data';
                break;
            // Snapshot: Study executed (either success or fail)
            case 'EXECUTED':
                this.icon = 'clock-outline';
                this.iconStatus = 'warning';
                this.iconLabel = 'Executed';
                break;
            // Data file: valid (validation, backend) or success (upload, frontend)
            case 'VALID':
            case 'SUCCESS':
                this.icon = 'checkmark-circle-2-outline';
                this.iconStatus = 'success';
                this.iconLabel = 'Success';
                break;
            // Data file: invalid (validation, backend) or failed (upload, frontend)
            case 'INVALID':
            case 'FAILED':
                this.icon = 'close-circle-outline';
                this.iconStatus = 'danger';
                this.iconLabel = 'Failed';
                break;
            // Dataset / snapshot: pending delete
            case 'PENDING_DELETE':
                this.icon = 'close-circle-outline';
                this.iconStatus = 'basic';
                this.iconLabel = 'Pending Delete';
                break;
            // Dataset / snapshot: deleted
            case 'DELETED':
                this.icon = 'close-circle-outline';
                this.iconStatus = 'basic';
                this.iconLabel = 'Deleted';
                break;
            default:
                this.icon = 'close-circle-outline';
                this.iconStatus = 'basic';
                this.iconLabel = 'unknown';
        }

        this.cd.detectChanges();
    }
}
