import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Page} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-submenu',
    template: `
        <div class="submenu-items" *ngIf="items">
            <ng-container *ngFor="let child of items">
                <div
                    *ngIf="!child.children"
                    class="submenu-item"
                    [ngClass]="{'submenu-item-disabled': !child.enabled}"
                    [routerLink]="child.link"
                    routerLinkActive="active"
                >
                    {{ child.title | titlecase }}
                </div>

                <ng-container *ngIf="child.children">
                    <ng-container *ngFor="let item of [child]">
                        <div class="flex pr-3">
                            <ngx-icon-box
                                (click)="item.children && (item.opened = true); $event.stopPropagation()"
                                class="menu-item-link"
                                [routerLink]="item.redirectTo"
                                routerLinkActive="active"
                                #rla="routerLinkActive"
                                [title]="item.title"
                            >
                            </ngx-icon-box>
                            <ng-container *ngIf="item.children">
                                <ngx-icon-box
                                    (click)="item.opened = !item.opened; $event.stopPropagation()"
                                    class="open-arrow"
                                    [icon]="item.opened ? 'chevron-down-outline' : 'chevron-left-outline'"
                                >
                                </ngx-icon-box>
                            </ng-container>
                        </div>

                        <div *ngIf="item.opened">
                            <ngx-submenu [items]="item.children"></ngx-submenu>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    `,
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent {
    @Input() items: Page[];
}
