export const DEFAULT_THEME = {
    name: 'default',
    base: null,
    variables: {
        chartColors: [
            '#ff5e29', // 0
            '#ffaa00', // 1
            '#ffd500', // 2
            '#b7db00', // 3
            '#84db2c', // 4
            '#4ca5ff', // 5
            '#ff708d', // 6
            '#00b383', // 7
            '#fad36d', // 8
            '#424d69', // 9
            '#c853ff', // 10
            '#776eff', // 11
            '#34cad3', // 12
            '#a6aebd', // 13
            '#ffd5de', // 14
            '#b4e9db', // 15
            '#fef3d5', // 16
            '#eabeff', // 17
            '#cae5ff', // 18
            '#e9f4b1', // 19
            '#ffe6b3', // 20
            '#d6d3ff', // 21
            '#c1eef2', // 22
            '#e4e6eb', // 23
            '#424d69', // 24
            '#879ac3', // 25
        ],
        optimizerColors: [
            '#f78279', // 0
            '#c00000', // 1
            '#bdd7ee', // 2
            '#0070c0', // 3
            '#20cd17', // 4
            '#a0ffa7', // 5
            '#ffc000', // 6
            '#fff0a0', // 7
            '#c00000', // 8
            '#f78279', // 9
            '#ff7c80', // 10
            '#ffccc0', // 11
        ],

        demandFactors: [
            '#579FE6', //0
            '#C2ECFF', //1
            '#00B383', //2
            '#D5F2C4', //3
            '#FAD36D', //4
            '#FFF8BF', //5
            '#FFB660', //6
            '#FFCF96', //7
            '#FF708D', //8
            '#FFC9D5', //9
        ],

        primary_default: '#fe9339',
        secondary_default: '#0c8ac7',
        warn_default: '#f0735b',

        fontColor: '#202840',
        fontHintColor: '#9fa9bd',
        backgroundChartColor: '#fff',
        backgroundBasicColor1: '#fff',
        tooltipBg: '#ffffff',

        axisSliderBackground: '#ffffff', // color-basic-100
        axisSliderShadow: '#c5ccdb', // color-basic-500
        axisSliderHandle: '#9fa9bd', // color-basic-600

        axisNameText: '#8992A3',
        axisLine: '#e0e6f1',
    },
};
