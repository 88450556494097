<nb-card>
    <nb-card-header style="padding-bottom: 5px">{{ title }}</nb-card-header>

    <nb-card-body class="chart-card-body degradation-card">
        <!-- Study collection dropdown -->
        <nb-select
            style="min-width: 375px"
            placeholder="Select Study Collection"
            (selectedChange)="onStudyCollectionChange($event)"
        >
            <nb-option *ngFor="let collection of studyCollections" [value]="collection.id">
                {{ collection.description }}
            </nb-option>
        </nb-select>

        <div style="padding-left: 15px">
            <nb-radio-group *ngIf="activeCollection" (valueChange)="onStudySelected($event)">
                <nb-radio *ngFor="let study of activeCollection.studyList" [value]="study.id">
                    {{ study.name }}
                </nb-radio>
            </nb-radio-group>
        </div>
    </nb-card-body>
</nb-card>
