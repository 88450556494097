<div class="header-container">
    <ng-container *ngIf="sidebarStore.sidebarStatus$ | async as status">
        <!-- Icon, navigate to dashboard -->
        <div
            class="sidebar-header-container"
            [routerLink]="['/dashboard']"
            [ngClass]="{
                'sidebar-header-container-compacted': !status.expanded
            }"
        >
            <div class="logo" [ngClass]="{'logo-collapsed': !status.expanded}"></div>
        </div>

        <!-- Main content -->
        <div class="header-container-center">
            <!-- If not on dashboard -->
            <ng-container *ngIf="pagesStore.activeFeature$ | async as activeGroup; else welcomeTemplate">
                <!-- Expand / collapse left navitation panel -->
                <ngx-icon-box
                    class="toggle-element header-item"
                    (click)="sidebarStore.toggleSidebarState(!status.expanded)"
                    [title]="status.expanded ? 'Hide' : 'Expand'"
                    direction="column"
                    icon="menu-outline"
                >
                </ngx-icon-box>

                <!-- Banner for pre-production environment -->
                <div *ngIf="staging" class="staging-env-banner-main">PRE-PRODUCTION</div>

                <!-- Feature title and icon -->
                <ngx-icon-box
                    class="header-item current-item"
                    [title]="activeGroup.title"
                    [iconDetails]="activeGroup.icon"
                >
                </ngx-icon-box>

                <!-- Top navigation menu -->
                <ng-container *ngFor="let group of pagesStore.pageGroups$ | async">
                    <ngx-icon-box
                        *ngIf="group.title"
                        [nbPopover]="popoverTemplate"
                        nbPopoverPlacement="bottom"
                        nbPopoverTrigger="hover"
                        class="header-item header-context-item"
                        [title]="group.title"
                        direction="column"
                        [iconDetails]="group.icon"
                    >
                        <ng-template #popoverTemplate>
                            <ngx-pages-group-popover [pages]="group.children"></ngx-pages-group-popover>
                        </ng-template>
                    </ngx-icon-box>
                </ng-container>
            </ng-container>
        </div>

        <!-- User settings menu -->
        <ngx-header-settings class="user-component"></ngx-header-settings>
    </ng-container>
</div>

<!-- Header on dashboard -->
<ng-template #welcomeTemplate>
    <ng-container *ngIf="(authService.getToken() | async).getPayload() as user">
        <ngx-icon-box
            class="welcome-header"
            [title]="'Welcome, ' + user.firstName + ' ' + user.lastName"
            icon="home-outline"
        >
        </ngx-icon-box>

        <div class="header-logo-container">
            <div class="header-logo"></div>

            <!-- Banner for pre-production environment -->
            <div *ngIf="staging" class="staging-env-banner-dashboard">PRE-PRODUCTION</div>
        </div>
    </ng-container>
</ng-template>
