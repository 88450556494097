<div class="value-box-container">
    <label class="value-box-title">{{ title }}</label>
    <label class="value-box-value" *ngIf="!formattedValue">
        {{ valuePrefix ? valuePrefix + " " : "" }}{{ value | ngxNumberWithCommas }}
        {{ valueSuffix }}
    </label>
    <!--
    <label class="value-box-value" *ngIf="formattedValue">
      {{ formattedValue }}
    </label>
    -->
    <div class="value-box-border"></div>
</div>
