<button
    nbButton
    class="action-button"
    status="info"
    size="medium"
    (click)="doAction.emit()"
    [nbPopover]="labelBox"
    [nbPopoverContext]="text"
    nbPopoverPlacement="right"
    nbPopoverTrigger="hover"
>
    <ngx-icon-box class="button-text" [title]="expanded ? text : ''" [icon]="icon"></ngx-icon-box>
</button>

<ng-template #labelBox let-text>
    <ngx-sidebar-action-popover [text]="text" [expanded]="expanded"></ngx-sidebar-action-popover>
</ng-template>
