import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-empty-label-value-vertical',
    templateUrl: './label-value-vertical.component.html',
    styleUrls: ['./label-value-vertical.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyLabelValueVerticalComponent implements OnInit {
    @Input() count: number = 1;

    constructor() {}

    iterArray: number[] = [];

    ngOnInit() {
        for (let i = 0; i < this.count; i++) {
            this.iterArray.push(i);
        }
    }
}
