import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersService} from '../../interfaces/common/users';
import {UsersServiceImpl} from './services/users.service';
import {UsersApi} from './api/users.api';
import {HttpService} from './api/http.service';
import {PagesService} from '../../interfaces/common/pages';
import {PagesApi} from './api/pages.api';
import {RolesApi} from './api/roles.api';
import {RolesService} from '../../interfaces/common/roles';
import {RolesServiceImpl} from './services/roles.service';
import {SupportServiceImpl} from './services/support.service';
import {SupportApi} from './api/support.api';
import {SupportService} from '../../interfaces/common/support';
import {PagesServiceImpl} from './services/pages.service';
import {SnapshotServiceImpl} from './services/snapshot.service';
import {DataFileServiceImpl} from './services/data-file.service';
import {ConfigurationServiceImpl} from './services/configuration.service';
import {DatasetService} from '../../interfaces/common/dataset';
import {DatasetServiceImpl} from './services/dataset.service';
import {SnapshotApi} from './api/snapshot.api';
import {DataFileApi} from './api/data-file-api.service';
import {ConfigurationApi} from './api/configuration.api';
import {DatasetApi} from './api/dataset.api';
import {SnapshotService} from '../../interfaces/common/snapshot';
import {DataFileService} from '../../interfaces/common/data-file';
import {ConfigurationService} from '../../interfaces/common/configuration';
import {DataPreparationApi} from '@core/backend/engin/api/data-preparation.api';
import {DataPreparationServiceImpl} from '@core/backend/engin/services/data-preparation.service';
import {DataPreparationService} from '@core/interfaces/engin/data-preparation';

const API = [
    HttpService,
    UsersApi,
    RolesApi,
    PagesApi,
    SupportApi,
    SnapshotApi,
    DataFileApi,
    ConfigurationApi,
    DatasetApi,
    DataPreparationApi,
];

const SERVICES = [
    HttpService,
    {provide: UsersService, useClass: UsersServiceImpl},
    {provide: RolesService, useClass: RolesServiceImpl},
    {provide: PagesService, useClass: PagesServiceImpl},
    {provide: SupportService, useClass: SupportServiceImpl},
    {provide: ConfigurationService, useClass: ConfigurationServiceImpl},
    {provide: DataFileService, useClass: DataFileServiceImpl},
    {provide: SnapshotService, useClass: SnapshotServiceImpl},
    {provide: DatasetService, useClass: DatasetServiceImpl},
    {provide: DataPreparationService, useClass: DataPreparationServiceImpl},
];

@NgModule({
    imports: [CommonModule],
})
export class CommonBackendModule {
    static forRoot(): ModuleWithProviders<CommonBackendModule> {
        return {
            ngModule: CommonBackendModule,
            providers: [...API, ...SERVICES],
        };
    }
}
