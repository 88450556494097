import {Observable} from 'rxjs';
import {Report} from '../common/pages';

export interface ReportLink {
    link: string;
}

export class ReportRequest {
    reportId: string;
    templateFlag: boolean;
    studyId: string;
    userId: string;
    sensitivityFlag: boolean;

    constructor(report: Report, studyId: string, userId: string, sensitivityFlag: boolean) {
        this.reportId = report ? report.reportId : '';
        this.templateFlag = report ? report.templateFlag : true;
        this.studyId = studyId;
        this.userId = userId;
        this.sensitivityFlag = sensitivityFlag;
    }
}

export interface OutputReportSource {
    links: [string];
}

export interface StudyCollection {
    id: string;
    name: string;
    label: string;
    description: string;
    runTime: string;
    duration: string;
    status: string;
    isSuccess: boolean;
    inputData: {
        assetDate: string;
        evaluationDate: string;
        healthIndexDate: string;
    };
    snapshotId: number;
    studyList: StudySummaryData[];
}

export interface StudySummaryData {
    id: string;
    name: string;
    description: string;
    sensitivityCode: string;
    isDefault: boolean;
}

export interface Study {
    id: string;
    name: string;
    description: string;
    sensitivityCode: string;
    isDefault: boolean;
    status: string;
    label: string;
    currentYear: number;
    evaluationPeriod: number;
    endedAt: number;
    inputData: {
        assetDate: string;
        evaluationDate: string;
        healthIndexDate: string;
    };
    isSuccess: boolean;
    params: [StringSet];
}

export interface StringSet {
    param: string;
    value: string;
}

export abstract class JobsService {
    // TODO: refactor into workflow controller
    abstract getStudyCollections(): Observable<StudyCollection[]>;

    // TODO: refactor into analyzer
    abstract getJobAssetInfo(jobId: string, assetId: string): Observable<any>;

    // TODO: refactor into reporting API, or workflow API
    abstract getOutputReportGeneric(reportRequest: ReportRequest): Observable<OutputReportSource>;

    // TODO: refactor into user API
    abstract updateDefaultStudyStrategy(latestAsDefault: boolean, defaultWorkflowId: string): Observable<any>;

    /* deprecated */
    abstract legacyExecuteNewStudyCollection(automated: boolean, dataType?: string, date?: string): Observable<any>;

    /* deprecated */
    abstract legacyExecuteHealthIndexStudy(automated: boolean, date?: string): Observable<boolean>;
}
