import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DatasetItem, TenantUnitsConfig, WorkflowConfig} from '@core/interfaces/common/configuration';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class ConfigurationApi {
    private readonly prefix = 'configuration';

    constructor(private api: HttpService) {}

    getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>> {
        return this.api.get(`${this.prefix}/workflow`, {});
    }

    getDatasets(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.get(`${this.prefix}/dataset`);
    }

    getTablesFromSchema(schemaName: string): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/schema-table/${schemaName}`);
    }

    getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>> {
        return this.api.get(`${this.prefix}/units`);
    }
}
