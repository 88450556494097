import {Component} from '@angular/core';
import {EMPTY_CHART_COLOR} from '../empty-chart-config';

@Component({
    selector: 'ngx-empty-bar-chart-stacked',
    templateUrl: './bar-chart-stacked.component.html',
    styleUrls: ['./bar-chart-stacked.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyBarChartStackedComponent {
    textBackGroundColor = EMPTY_CHART_COLOR.color;
    barColor = EMPTY_CHART_COLOR.color;
    transparent = EMPTY_CHART_COLOR.transparent;

    legendTextStyle = {
        color: this.transparent,
        backgroundColor: this.textBackGroundColor,
        borderRadius: 3,
    };
    yAxisDataTextStyle = {
        borderRadius: 3,
        borderColor: this.textBackGroundColor,
        backgroundColor: this.textBackGroundColor,
        color: this.transparent,
    };

    chartOptions = {
        animation: false, // disable to loading animation
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        title: {
            x: 'center',
            text: 'Chart Title',
            textStyle: {
                fontSize: 14,
                color: this.transparent,
            },
            backgroundColor: this.textBackGroundColor,
            borderRadius: 3,
        },
        legend: {
            data: [
                {
                    name: 'Legend 1',
                    icon: 'none',
                    textStyle: this.legendTextStyle,
                },
                {
                    name: 'Legend 2',
                    icon: 'none',
                    textStyle: this.legendTextStyle,
                },
                {
                    name: 'Legend 3',
                    icon: 'none',
                    textStyle: this.legendTextStyle,
                },
            ],
            y: 'bottom',
        },
        grid: {
            top: 30,
            left: 155,
            right: 50,
            bottom: 30,
        },
        xAxis: {
            type: 'value',
            show: false,
        },
        yAxis: {
            type: 'category',
            data: [
                {
                    value: 'yAxis 1',
                    textStyle: this.yAxisDataTextStyle,
                },
                {
                    value: 'yAxis 2',
                    textStyle: this.yAxisDataTextStyle,
                },
                {
                    value: 'yAxis 3',
                    textStyle: this.yAxisDataTextStyle,
                },
            ],
        },
        series: [
            {
                name: 'Legend 1',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                data: [
                    {
                        value: 15,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 12,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 50,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                ],
            },
            {
                name: 'Legend 2',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                data: [
                    {
                        value: 15,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 42,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 10,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                ],
            },
            {
                name: 'Legend 3',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                data: [
                    {
                        value: 15,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 50,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                    {
                        value: 10,
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: this.barColor,
                        },
                    },
                ],
            },
        ],
    };
}
