import {Injectable} from '@angular/core';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SystemLoadForecastService} from '@core/interfaces/engin/load-forecast/system';
import {SystemLoadForecastApi} from '@core/backend/engin/api/load-forecast/system.api';

@Injectable()
export class SystemLoadForecastServiceImpl extends SystemLoadForecastService {
    constructor(private api: SystemLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for system
     */
    public getSystemForecastBySeasons(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getSystemForecastBySeasons(
            workflowItemId,
            scenarioId,
            period,
            subsystemId,
            unit,
            sensitivityId,
        );
    }

    public getSystemForecastBreakdown(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getSystemForecastBreakdown(
            workflowItemId,
            scenarioId,
            period,
            subsystemId,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getSystemForecastBreakdownCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getSystemForecastBreakdownCSV(
            workflowItemId,
            scenarioId,
            period,
            subsystemId,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getSystemForecastByFactors(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getSystemForecastByFactors(
            workflowItemId,
            scenarioId,
            period,
            subsystemId,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getSystemForecastPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getSystemForecastPivot(
            workflowItemId,
            scenarioId,
            period,
            subsystemId,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getSystemForecastPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getSystemForecastPivotCSV(
            workflowItemId,
            scenarioId,
            period,
            subsystemId,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getSystemViolationsByInfrastructureType(
        workflowItemId: string,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld> {
        return this.api.getSystemViolationsByInfrastructureType(
            workflowItemId,
            scenarioId,
            seasonTiming,
            period,
            viewBy,
            unit,
            sensitivityId,
        );
    }

    public getSystemViolationsPivot(
        workflowItemId: string,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getSystemViolationsPivot(workflowItemId, scenarioId, seasonTiming, period, unit, sensitivityId);
    }

    public getSystemViolationsPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getSystemViolationsPivotCSV(
            workflowItemId,
            scenarioId,
            seasonTiming,
            period,
            unit,
            sensitivityId,
        );
    }

    public getSystemViolationBreakdown(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getSystemViolationBreakdown(workflowItemId, scenarioId, period, viewBy, unit, sensitivityId);
    }

    public getSystemViolationBreakdownPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getSystemViolationBreakdownPivot(
            workflowItemId,
            scenarioId,
            period,
            viewBy,
            unit,
            sensitivityId,
        );
    }

    public getSystemViolationBreakdownPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getSystemViolationBreakdownPivotCSV(
            workflowItemId,
            scenarioId,
            period,
            viewBy,
            unit,
            sensitivityId,
        );
    }
}
