import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

export abstract class ReportingService {
    // abstract initSettingsByWorkflowItem(workflowItemId: string): Observable<LoadForecastSettings>;

    abstract getDataExtractPreview(
        workflowItemId: string,
        outputCategory: string,
        outputType: string,
    ): Observable<DataSource>;

    abstract getDataExtractCsv(
        workflowItemId: string,
        outputCategory: string,
        outputType: string,
        outputResult: string,
    ): Observable<APIResponse<DownloadURL>>;
}
