import {Injectable} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';

@Injectable()
export class StudySelectorViewModel {
    onChange: FormControl = this.fb.control(null);

    onCheckBoxChange(value) {
        this.onChange.setValue(value);
    }

    constructor(protected fb: FormBuilder) {}
}
