import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PagesStore, SidebarStore} from '../../../@store';
import {NbAuthService} from '@nebular/auth';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() currentUser: any;

    constructor(public pagesStore: PagesStore, public sidebarStore: SidebarStore, public authService: NbAuthService) {}

    staging: boolean = environment.staging;
}
