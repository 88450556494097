import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../@core/interfaces/common/users';
import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';
import {FormatsService} from '../../@core/utils';
import {filter} from 'rxjs/operators';
import {UsersStore} from '../../@store/common/users.store';

@Pipe({
    name: 'unlocalizeTime',
    pure: false,
})
export class TimeUnlocalizePipe implements PipeTransform {
    private localTimezone: string = 'Etc/UTC'; // default utc
    constructor(private usersStore: UsersStore) {
        this.usersStore.currentUser$.pipe(filter((d) => !!d)).subscribe((user: User) => {
            this.localTimezone = user.timezone;
        });
    }

    /**
     * Convert localized time back to universal (UTC) time; ignore any timezone inDateRaw in favour of user's local time
     * saved in ENGIN.
     * @param inDateRaw input date as type date or string
     * @param outFormat optional else DEFAULT_DATETIME_FORMAT = 'yyyy-MM-DD HH:mm A'
     */
    transform(inDateRaw: any, outFormat: any = FormatsService.DEFAULT_DATETIME_FORMAT): any {
        // moment.tz() interprets ambiguous input in specific timezone
        const inLocalMoment: Moment =
            typeof inDateRaw === 'string' ? moment.tz(inDateRaw, this.localTimezone) : moment(inDateRaw);

        const utcMoment: Moment = inLocalMoment.utc();
        const dateString: string = utcMoment.format(outFormat);
        return dateString;
    }
}
