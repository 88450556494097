import {ChangeDetectionStrategy, Component, EventEmitter} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {NbAccessChecker} from '@nebular/security';

@Component({
    selector: 'ngx-file-download',
    template: `
        <a
            class="pointer"
            *ngIf="accessChecker.isGranted('download', 'uploads') | async; else readonly"
            (click)="valueClick.emit(rowData.fileKey)"
            >{{ value }}</a
        >
        <ng-template #readonly>
            <a>{{ value }}</a>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDownloadComponent implements ViewCell {
    rowData: any;
    value: string | number;

    valueClick = new EventEmitter<string>();

    constructor(public accessChecker: NbAccessChecker) {}
}
