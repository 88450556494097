import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DataFile, FILE_FORMAT, PreSingedURL, UploadFileCreate} from '../../../interfaces/common/data-file';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DatasetType} from '../../../interfaces/common/dataset';
import {APIResponse} from '../../../interfaces/system/system-common';

@Injectable()
export class DataFileApi {
    private readonly prefix = 'data/file';

    constructor(private api: HttpService) {}

    findOne(id: string): Observable<APIResponse<DataFile>> {
        return this.api.get(`${this.prefix}/${id}`);
    }

    create(data: UploadFileCreate): Observable<APIResponse<DataFile>> {
        return this.api.post(`${this.prefix}/`, data);
    }

    processFile(uploadId: string): Observable<APIResponse<DataFile>> {
        return this.api.put(`${this.prefix}/process/${uploadId}`, {});
    }

    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': fileData.type,
            },
        });
    }

    getPresignedUrl(
        fileName: string,
        directory: string,
        fileFormat: FILE_FORMAT,
    ): Observable<APIResponse<PreSingedURL>> {
        return this.api.post(`${this.prefix}/presigned-url/${fileName}`, {
            directory: directory,
            fileFormat: fileFormat,
        });
    }

    verifyUpload(fileName: string): Observable<APIResponse<any>> {
        return this.api.get(`${this.prefix}/verify-upload/${fileName}`);
    }

    listFiles(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list`);
    }

    listFilesBySchema(datasetType: DatasetType, status: string): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.prefix}/list?filterBydatasetType_EQUAL=${datasetType}&filterBystatus_EQUAL=${status}`,
        );
    }

    downloadFileByIds(ids: number[]): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/download-files?uploadFileIds=${ids}`);
    }
}
