import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioButtonOption} from './inline-radio-group.interface';

@Component({
    selector: 'ngx-inline-radio-group',
    templateUrl: './inline-radio-group.component.html',
    styleUrls: ['./inline-radio-group.component.scss'],
})
export class InlineRadioGroupComponent {
    @Input() options: RadioButtonOption[];
    @Input() curValue: any;
    @Input() formControlName: string;
    @Output() curValueChange: EventEmitter<any> = new EventEmitter<any>();

    changeValue(value) {
        this.curValueChange.emit(value);
    }

    public customCompare = function (a, b) {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    };
}
