<div class="popover-container" *ngIf="pages.length > 0">
    <div class="selector-arrow"></div>
    <div class="popover-items">
        <ngx-icon-box
            *ngFor="let page of pages"
            class="popover-item"
            (click)="topMenuClicked()"
            [ngClass]="{'disabled-link': !page.enabled}"
            [routerLink]="page.link"
            routerLinkActive="active"
            [title]="page.title"
            direction="column"
            [iconDetails]="page.icon"
        >
        </ngx-icon-box>
    </div>
</div>
