<!-- Table card -->
<nb-card class="card mb-0">
    <!-- Header -->
    <nb-card-header *ngIf="enableHeader()" class="card-header">
        <!-- Header part 1: title, subtitle. [Optional] Global search -->
        <div [ngClass]="{'col-12' : !enablePagePerRecord(),'col-9': enablePagePerRecord() }">
            <div class="row">
                <div class="col-9 header-title-section">
                    <label class="primary-header">{{title}}</label>
                    <label class="sub-header">{{subTitle}}</label>
                </div>
                <div class="col-3 header-global-search" *ngIf="disableGlobalSearch()">
                    <input
                        nbInput
                        #search
                        class="search"
                        type="text"
                        placeholder="Search..."
                        (blur)="onSearch(search.value)"
                    />
                </div>
            </div>
        </div>

        <!-- [Optional] Header part 2: display records per page (input) -->
        <div class="vertical-separator" *ngIf="enablePagePerRecord()"></div>
        <div class="col-2 header-simple-pager" *ngIf="enablePagePerRecord()">
            <form [formGroup]="pagingFormGroup">
                <label class="simple-pager-label">Records on page</label>
                <input nbInput formControlName="perPage" type="text" placeholder="Rows" class="simple-pager-input" />
            </form>
        </div>
        <div class="vertical-separator" *ngIf="enablePagePerRecord()"></div>
        <div class="col-1 header-refresh-button" *ngIf="enablePagePerRecord()">
            <button nbButton (click)="reaload()">
                <nb-icon class="icon" icon="refresh" [pack]="'eva'"></nb-icon>
            </button>
        </div>
    </nb-card-header>

    <!-- Body -->
    <nb-card-body class="card-body">
        <ngx-smart-table
            [settings]="settings"
            [source]="data"
            (custom)="onCustomEventClick($event)"
            (userRowSelect)="onUserRowSelect($event)"
        >
        </ngx-smart-table>
    </nb-card-body>

    <!-- Footer -->
</nb-card>
