import {AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NbPopoverDirective} from '@nebular/theme';
import {HeaderFiltersStore} from '@store/load-forecast/header-filters.store';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'ngx-numeric-range-control',
    templateUrl: './numeric-range-control.component.html',
    styleUrls: ['./numeric-range-control.component.scss'],
    styles: [
        `
            .flex {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .form-field {
                width: 47%;
            }

            label {
                font-family: Roboto, 'Helvetica Neue', sans-serif !important;
                font-weight: 500 !important;
                margin-bottom: 0.25rem;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericRangeControlComponent),
            multi: true,
        },
    ],
})
export class NumericRangeControlComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

    @Input() localDefault?: {min: string; max: string};
    globalDefault?: {min: string; max: string};

    isPopoverShown: boolean = false;

    inputControl: FormControl = new FormControl();
    minControl: FormControl = new FormControl();
    maxControl: FormControl = new FormControl();

    onChange(_: any) {}

    writeValue(value: any) {
        this.minControl.setValue(+value.min || '', {emitEvent: false});
        this.maxControl.setValue(+value.max || '');
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched() {}

    constructor(private headerFiltersStore: HeaderFiltersStore) {}

    ngOnInit() {
        this.globalDefault = this.headerFiltersStore.getPeriodDefault();
        // Value changes trigger before user has ended their input; do not trigger min/max validation at this time
        this.minControl.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
            let valid = true;
            // Change non-integer values immediately
            if (value !== null && !this.isInteger(value)) {
                valid = false;
                value = Math.floor(value);
                this.minControl.setValue(Math.floor(value), {emitEvent: false});
            }

            // Invalid data should not create further events
            if (value !== null && value < +this.globalDefault?.min) {
                valid = false;
            }

            if (value !== null && value > +this.globalDefault?.max) {
                valid = false;
            }

            if (valid) {
                let min = value !== null ? value + '' : 'min',
                    max = this.maxControl.value !== null ? this.maxControl.value + '' : 'max';

                if (value !== null && this.maxControl.value !== null && this.maxControl.value < value) {
                    this.maxControl.setValue(value, {emitEvent: false});
                    max = this.maxControl.value + '';
                }

                let result = value == null && this.maxControl.value == null ? '' : `${min} — ${max}`;
                this.inputControl.setValue(result, {emitEvent: false});
            }
        });

        this.maxControl.valueChanges.pipe(debounceTime(500)).subscribe((value: number) => {
            let valid = true;
            // Change non-integer values immediately
            if (value !== null && !this.isInteger(value)) {
                valid = false;
                value = Math.floor(value);
                this.maxControl.setValue(Math.floor(value), {emitEvent: false});
            }

            // Invalid data should not create further events
            if (value !== null && value < +this.globalDefault?.min) {
                valid = false;
            }

            if (value !== null && value > +this.globalDefault?.max) {
                valid = false;
            }

            if (valid) {
                let min = this.minControl.value !== null ? this.minControl.value + '' : 'min',
                    max = value !== null ? value + '' : 'max';

                if (value !== null && this.minControl.value !== null && this.minControl.value > value) {
                    this.minControl.setValue(value, {emitEvent: false});
                    min = this.minControl.value + '';
                }

                let result = value == null && this.minControl.value == null ? '' : `${min} — ${max}`;
                this.inputControl.setValue(result, {emitEvent: false});
            }
        });

        this.inputControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: string) => {
            if (!value) {
                this.onChange({min: '', max: ''});
            } else if (this.inputControl.status === 'VALID') {
                this.onChange({min: this.minControl.value + '', max: this.maxControl.value + ''});
            }
        });
    }

    ngAfterViewInit() {
        this.popover?.nbPopoverShowStateChange.subscribe(({isShown}) => {
            if (!this.popover.isShown) {
                this.isPopoverShown = false;
                this.formValueValidation();
                this.completeValues();
            } else {
                this.isPopoverShown = true;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.localDefault && changes.localDefault.currentValue) {
            setTimeout(() => {
                this.minControl.setValue(+this.localDefault?.min || '', {emitEvent: false});
                this.maxControl.setValue(+this.localDefault?.max || '');
            });
        }
    }

    private formValueValidation() {
        // Validate min value or reset within appropriate boundaries
        const minVal = this.minControl.value;
        if (minVal !== null && minVal < +this.globalDefault?.min) {
            this.minControl.setValue(+this.globalDefault?.min, {emitEvent: false});
        }

        if (minVal !== null && minVal > +this.globalDefault?.max) {
            this.minControl.setValue(+this.globalDefault?.max, {emitEvent: false});
        }

        // Validate max value or reset within appropriate boundaries
        const maxValue = this.minControl.value;
        if (maxValue !== null && maxValue < +this.globalDefault?.min) {
            this.maxControl.setValue(+this.globalDefault?.min, {emitEvent: false});
        }

        if (maxValue !== null && maxValue > +this.globalDefault?.max) {
            this.maxControl.setValue(+this.globalDefault?.max, {emitEvent: false});
        }
    }

    private completeValues() {
        if (this.minControl.value === null) this.minControl.setValue(this.maxControl.value);

        if (this.maxControl.value === null) this.maxControl.setValue(this.minControl.value);

        if (this.minControl.value === null && this.maxControl.value === null) {
            this.inputControl.reset();
        } else {
            let result = `${this.minControl.value} — ${this.maxControl.value}`;

            this.inputControl.setValue(result);
        }
    }

    reset() {
        this.minControl.reset();
        this.maxControl.reset();
    }

    isInteger(num: number) {
        return (num ^ 0) === num;
    }
}
