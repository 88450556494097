import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {PagesStore} from '../../../@store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {DisplaySettings, IconDetails, Page} from '../../../@core/interfaces/common/pages';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    @Input() waitingTemplate: TemplateRef<any>;
    @Input() popoutState$: Observable<any>;
    @Input() popoutCollapsed$: Observable<boolean>;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(public pagesStore: PagesStore) {}

    _pageIsModule: boolean;
    _widgetHidden: boolean = true;
    _title: string;
    _iconDetails: IconDetails;

    ngOnInit() {
        combineLatest<Observable<Page>, Observable<Page>, Observable<DisplaySettings>>([
            this.pagesStore.activePage$,
            this.pagesStore.activeTab$,
            this.pagesStore.currentDisplay$,
        ])
            .pipe(takeUntil(this.destroy$), debounceTime(50))
            .subscribe(([page, tab, currentDisplay]: [Page, Page, DisplaySettings]) => {
                // Data audit widget is displayed in modules only, but can be disabled in page settings
                this._pageIsModule = (currentDisplay.groupId === '' ? false : currentDisplay.groupId) === 'modules';
                this._widgetHidden = this._pageIsModule
                    ? currentDisplay.dataAuditWidgetHidden
                        ? currentDisplay.dataAuditWidgetHidden
                        : false
                    : true;

                // Page title formed from page and tab (if applicable); icons are unique at the page level
                this._title = tab ? page.title + ': ' + tab.title : page.title;
                this._iconDetails = page.icon;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
