import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {StudiesStore} from '../../../@store';
import {NbToastrService} from '@nebular/theme';
import {StudySelectorViewModel} from './study-selector.vm';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {JobsService} from '@core/interfaces/engin/jobs';

@Component({
    selector: 'ngx-default-study-selector',
    template: `
        <ng-container [class.invisible]="!(studiesStore.defaultWorkflowId$ | async)">
            <nb-icon
                [icon]="
                    +(studiesStore.defaultWorkflowId$ | async) === +rowWorkflowId
                        ? 'radio-button-on'
                        : 'radio-button-off'
                "
                #icon
                [ngStyle]="defaultValue ? {pointerEvents: 'none', color: '#9fa9bd'} : {}"
                [ngClass]="{
                    'default-study-icon': +(studiesStore.defaultWorkflowId$ | async) === +rowWorkflowId,
                    'not-default-study-icon': +(studiesStore.defaultWorkflowId$ | async) !== +rowWorkflowId
                }"
                (click)="setDefaultCollection(rowData)"
            >
            </nb-icon>
        </ng-container>
    `,
    styleUrls: ['./study-selector.component.scss'],
})
export class DefaultStudySelectorComponent extends Unsubscribable implements ViewCell, OnInit {
    @ViewChild('icon') icon: ElementRef;
    @Input() rowData: any;
    @Input() value: string | number;

    defaultWorkflowId: number;
    rowWorkflowId: number;

    defaultValue;

    constructor(
        public studiesStore: StudiesStore,
        private jobsData: JobsService,
        private studySelectorViewModel: StudySelectorViewModel,
        private toastrService: NbToastrService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.studiesStore.latestAsDefault$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            this.defaultValue = value;
        });

        this.studiesStore.defaultWorkflowId$.pipe(takeUntil(this.unsubscribe$)).subscribe((defaultId) => {
            this.defaultWorkflowId = parseInt(defaultId, 10);
        });

        this.studySelectorViewModel.onChange.valueChanges.subscribe((value) => {
            this.onChangeActionForCheckBox(value);
        });

        this.rowWorkflowId = this.rowData.id;
    }

    onChangeActionForCheckBox(value: any) {
        const iconTag = this.icon['el'].nativeElement.style;
        if (value) {
            iconTag.pointerEvents = 'none';
            iconTag.color = '#9fa9bd';
        } else {
            iconTag.pointerEvents = 'auto';
            iconTag.color = '';
        }
    }

    setDefaultCollection(rowData) {
        if (rowData.status !== 'SUCCESS') {
            this.toastrService.danger(
                'Study collection is incomplete and cannot be set to default collection. Please ' +
                    'select a study collection which has been successfully completed.',
                'Default study collection not updated!',
                {duration: 10000},
            );
        } else {
            this.studiesStore.setDefaultWorkflowId(this.rowData.id.toString());
            this.jobsData.updateDefaultStudyStrategy(false, this.rowData.id.toString()).subscribe();
        }
    }
}
