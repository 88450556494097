import {ModuleWithProviders, NgModule} from '@angular/core';
import {JobsApi} from './api/jobs.api';
import {JobsService} from '../../interfaces/engin/jobs';
import {JobsServiceImpl} from './services/jobs.service';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from '../common/common-backend.module';
import {WorkflowApi} from './api/workflow.api';
import {WorkflowService} from '@core/interfaces/engin/workflow';
import {WorkflowServiceImpl} from './services/workflow.service';
import {LoadForecastApi} from '@core/backend/engin/api/load-forecast/load-forecast.api';
import {LoadForecastService} from '@core/interfaces/engin/load-forecast/load-forecast';
import {LoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/load-forecast.service';
import {DataPreparationService} from '@core/interfaces/engin/data-preparation';
import {DataPreparationServiceImpl} from '@core/backend/engin/services/data-preparation.service';
import {DataPreparationApi} from '@core/backend/engin/api/data-preparation.api';
import {ReportingService} from '@core/interfaces/engin/reporting';
import {ReportingServiceImpl} from '@core/backend/engin/services/reporting.service';
import {ReportingApi} from '@core/backend/engin/api/reporting.api';
import {ScenarioLoadForecastService} from '@core/interfaces/engin/load-forecast/scenario';
import {ScenarioLoadForecastApi} from '@core/backend/engin/api/load-forecast/scenario.api';
import {ScenarioLoadForecastServiceImpl} from './services/load-forecast/scenario.service';
import {StationLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/station.service';
import {SystemLoadForecastService} from '@core/interfaces/engin/load-forecast/system';
import {SystemLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/system.service';
import {StationLoadForecastService} from '@core/interfaces/engin/load-forecast/station';
import {FeederLoadForecastService} from '@core/interfaces/engin/load-forecast/feeder';
import {FeederLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/feeder.service';
import {VaultLoadForecastService} from '@core/interfaces/engin/load-forecast/vault';
import {DemandFactorService} from '@core/interfaces/engin/load-forecast/demand-factor';
import {VaultLoadForecastServiceImpl} from '@core/backend/engin/services/load-forecast/vault.service';
import {DemandFactorServiceImpl} from '@core/backend/engin/services/load-forecast/demand-factor.service';
import {SystemLoadForecastApi} from '@core/backend/engin/api/load-forecast/system.api';
import {StationLoadForecastApi} from '@core/backend/engin/api/load-forecast/station.api';
import {FeederLoadForecastApi} from '@core/backend/engin/api/load-forecast/feeder.api';
import {VaultLoadForecastApi} from '@core/backend/engin/api/load-forecast/vault.api';
import {DemandFactorApi} from '@core/backend/engin/api/load-forecast/demand-factor.api';
import {SectionLoadForecastServiceImpl} from './services/load-forecast/line-section.service';
import {SectionLoadForecastService} from '@core/interfaces/engin/load-forecast/line-section';
import {SectionLoadForecastApi} from '@core/backend/engin/api/load-forecast/line-section.api';
import {MeterLoadForecastApi} from './api/load-forecast/meter.api';
import {DxTransformerLoadForecastService} from '@core/interfaces/engin/load-forecast/dx-transformer';
import {DxTransformerLoadForecastServiceImpl} from './services/load-forecast/dx-transformer.service';
import {MeterLoadForecastService} from '@core/interfaces/engin/load-forecast/meter';
import {MeterLoadForecastServiceImpl} from './services/load-forecast/meter.service';
import {DxTransformerLoadForecastApi} from '@core/backend/engin/api/load-forecast/dx-transformer.api';

const API = [
    /* deprecated */
    JobsApi,
    WorkflowApi,
    LoadForecastApi,
    ScenarioLoadForecastApi,
    SystemLoadForecastApi,
    StationLoadForecastApi,
    FeederLoadForecastApi,
    VaultLoadForecastApi,
    SectionLoadForecastApi,
    DxTransformerLoadForecastApi,
    MeterLoadForecastApi,
    DemandFactorApi,
    ReportingApi,
    DataPreparationApi,
];

const SERVICES = [
    {provide: JobsService, useClass: JobsServiceImpl},
    {provide: WorkflowService, useClass: WorkflowServiceImpl},
    {provide: LoadForecastService, useClass: LoadForecastServiceImpl},
    {provide: ScenarioLoadForecastService, useClass: ScenarioLoadForecastServiceImpl},
    {provide: SystemLoadForecastService, useClass: SystemLoadForecastServiceImpl},
    {provide: StationLoadForecastService, useClass: StationLoadForecastServiceImpl},
    {provide: FeederLoadForecastService, useClass: FeederLoadForecastServiceImpl},
    {provide: VaultLoadForecastService, useClass: VaultLoadForecastServiceImpl},
    {provide: SectionLoadForecastService, useClass: SectionLoadForecastServiceImpl},
    {provide: DxTransformerLoadForecastService, useClass: DxTransformerLoadForecastServiceImpl},
    {provide: MeterLoadForecastService, useClass: MeterLoadForecastServiceImpl},
    {provide: DemandFactorService, useClass: DemandFactorServiceImpl},
    {provide: ReportingService, useClass: ReportingServiceImpl},
    {provide: DataPreparationService, useClass: DataPreparationServiceImpl},
];

@NgModule({
    imports: [CommonModule, CommonBackendModule],
    exports: [CommonBackendModule],
})
export class EnginBackendModule {
    static forRoot(): ModuleWithProviders<EnginBackendModule> {
        return {
            ngModule: EnginBackendModule,
            providers: [...API, ...SERVICES],
        };
    }
}
