import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, PeriodFilter} from './load-forecast';

export abstract class DemandFactorService {
    /*
     * Demand factors
     */
    // Pivot data
    abstract getOrganicGrowthPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource>;

    abstract getLoadTransferPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource>;

    abstract getPhotovoltaicPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource>;

    abstract getElectricVehiclePivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource>;

    abstract getLLAConnectionPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource>;

    abstract getLLADevelopPivot(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<DataSource>;

    // Data download
    abstract getOrganicGrowthPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getLoadTransferPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getPhotovoltaicPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getElectricVehiclePivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getLLADevelopPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getLLAConnectionPivotCSV(
        workflowItemId: string,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
    ): Observable<APIResponse<DownloadURL>>;
}
